import { useState, useEffect } from "react";
import useStoreForm from "../../../../store/state";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Transition } from "@headlessui/react";
import { BoxArrResult, EditScreen, Form, IconAdd, IconSave, InputForm, ModalForm, Modal, TitleForm, ruleRequired } from "../components";
import { getDataSocmed } from "../../../../helper/api/getApi";

const formSocmed = [{
    code_master_id: "",
    username: "",
}];

export const RegistSocmed = ({ isOpenSideBar }) => {
    const [dataSosmed, setDataSosmed] = useState([]);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState(1); // 0 alert delete, 1 alert simpan, 2 edit
    const [isEditMode, setIsEditMode] = useState(true);
    const [msgAlert, setMsgAlert] = useState("Apakah anda sudah yakin dengan data ini?");
    const [statusSosmed, setStatusSosmed] = useState(1);

    const [selectKindSosmed, setSelectKindSosmed] = useState(null);

    const storeSocmed = useStoreForm(state => state?.socmed);
    const _sosmed = useStoreForm.getState()?.dataSosmed;

    const { formState: { errors, isValid }, control, handleSubmit } = useForm({
        defaultValues: {
            socmed: formSocmed
        },
    });

    const { fields, remove, append, prepend } = useFieldArray({
        control,
        name: 'socmed'
    })

    const watchSocmed = useWatch({
        control,
        name: 'socmed'
    });

    useEffect(() => {
        firstRender();
    }, [])

    function firstRender() {
        _sosmed.length > 0 ? setDataSosmed(_sosmed) : getDataSocmed().then(res => {
            useStoreForm.setState({ dataSosmed: res });
            setDataSosmed(res);
        }).catch(err => console.log("Error Sosmed"));

        const _data = storeSocmed?.data;
        const _isEdit = storeSocmed?.isEdit;

        Modal.setAppElement('body');
        if (Array.isArray(_data)) {
            if (_data.length == 0) {
                setIsEditMode(true)
            } else {
                prepend(_data);
            }
        }
        setIsEditMode(_isEdit);
    }

    function saveToStore(mode) {
        const filterValue = watchSocmed?.filter(value => value.code_master_id !== "");

        if (mode == 1) {
            useStoreForm.setState({ socmed: { data: filterValue, isEdit: false, isDone: true } });
        } else if (mode == 2) {
            useStoreForm.setState({ socmed: { data: filterValue, isEdit: true, isDone: false } });
        }
    }

    async function onHandleSubmit(data) {
        if (isValid) {
            append(formSocmed);
        } else {
            console.log("Error Handle")
        }
    }

    function removeItem(e, index) {
        e.preventDefault();
        remove(index);
    }

    const routePresAlert = () => {
        switch (typeAlert) {
            case 1:
                saveToStore(1);
                break;
            case 2:
                setIsEditMode(true);
                setIsOpenAlert(false);
                break;
            case 3:
                useStoreForm.setState({ socmed: { isDone: true, isEdit: false, data: [] } });
                setIsOpenAlert(false);
                break;
            default:
                setIsOpenAlert(false);
                break;
        }
    }

    function kindInput(value) {
        const foundSosmed = dataSosmed.find(e => e.value == value)
        switch (foundSosmed?.label) {
            case "Whatsapp":
                return 2;
            default:
                return 1;
        }
    }

    return (
        <Transition
            show={true}
            appear={true}
            enter="transition-all duration-700"
            enterFrom="transform -translate-x-full opacity-0"
            enterTo="transform translate-x-0 opacity-100"
            leave="transition-all duration-500"
            leaveFrom="transform translate-x-0 opacity-100"
            leaveTo="transform translate-x-full opacity-0"
            className="mx-auto inset-0 z-40 overflow-y-scroll"
        >
            <ModalForm
                isOpen={isOpenAlert}
                typeAlert={typeAlert}
                msgAlert={msgAlert}
                onPressFalse={() => setIsOpenAlert(false)}
                onPressTrue={routePresAlert}
            />
            <TitleForm title={'Sosial Media'} />
            <div className="flex divide-x-2 divide-slate-200 gap-3">
                {isEditMode || fields.length === 0 ? (
                    <Form className={`md:px-3 ${isOpenSideBar && ('hidden')} ${fields.length > 1 ? "w-1/2" : "w-full"} md:block gap-1`} onSubmit={handleSubmit(onHandleSubmit)}>
                        <div className="mt-4 flex items-center justify-end self-end gap-1.5">
                            <button onClick={() => {
                                if (isValid) {
                                    append(formSocmed);
                                    saveToStore(2);
                                }
                            }} className="uppercase bg-green-500 hover:bg-green-400 active:bg-green-300 transition-colors text-white text-xs font-semibold flex items-center self-end gap-1.5 rounded px-3 py-1.5 mt-4">
                                <IconAdd />
                                tambah
                            </button>
                        </div>
                        <Controller
                            rules={{ required: ruleRequired("Jenis Sosmed masih kosong") }}
                            control={control} name={`socmed[${fields.length - 1}].code_master_id`}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Jenis Sosmed"} isrequired={true} positionSelect={'relative'} isdropdown options={dataSosmed || []} onChange={e => { onChange(e.value); setStatusSosmed(kindInput(e.value)); setSelectKindSosmed(e) }} onBlur={onBlur} value={selectKindSosmed} ref={ref} errors={errors} controlname={`socmed[${fields.length - 1}].code_master_id`} />
                            )}
                        />
                        <Controller
                            rules={{ required: ruleRequired("Nama Pengguna masih kosong") }}
                            control={control} name={`socmed[${fields.length - 1}].username`}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={statusSosmed == 1 ? "Nama Pengguna" : "Nomor Telepon"} isrequired={true} type={statusSosmed == 1 ? "text" : "tel"} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`socmed[${fields.length - 1}].username`} />
                            )}
                        />
                        <div className="flex justify-center mt-2 gap-2">
                            <button onClick={() => {
                                if (isValid) {
                                    saveToStore(1);
                                }
                            }} className="uppercase cursor-pointer bg-pink-500 hover:bg-pink-400 active:bg-pink-300 transition-colors text-white text-xs font-semibold flex gap-1.5 rounded items-center px-3 py-1.5 mt-4">
                                <IconSave />
                                simpan
                            </button>
                            {/* {fields.length > 1 ? (
                                <div onClick={() => {
                                    setIsOpenAlert(true);
                                    setMsgAlert("Anda yakin dengan data yang akan di simpan ini?")
                                    setTypeAlert(1);
                                }} className="uppercase cursor-pointer bg-pink-500 hover:bg-pink-400 active:bg-pink-300 transition-colors text-white text-xs font-semibold flex gap-1.5 rounded items-center px-3 py-1.5 mt-4">
                                    <IconSave />
                                    simpan
                                </div>
                            ) : (
                                
                            )} */}
                        </div>
                    </Form>
                ) : (
                    <EditScreen titleForm={""} isHasItem={fields.length > 1} isOpenSideBar={isOpenSideBar} onPressEdit={() => {
                        // useStoreForm.setState({ socmed: { data: storeSocmed, isEdit: true, isDone: false } })
                        setIsEditMode(true);
                    }} />
                )}
                <ul className={`${fields.length > 1 ? "w-1/2" : "hidden"} pl-6 overflow-y-auto h-full`}>
                    {fields.length > 1 && (
                        fields.map((item, index) => {
                            const isLastItem = index === (fields.length - 1);
                            const findSosmed = dataSosmed.find(e => e?.value === item.code_master_id);
                            const typeUser = findSosmed?.label === "Whatsapp" ? 2 : 1;

                            return (
                                <Transition
                                    key={index}
                                    show={true}
                                    appear={true}
                                    enter="transition-all duration-700"
                                    enterFrom="transform -translate-x-full opacity-0"
                                    enterTo="transform translate-x-0 opacity-100"
                                    leave="transition-all duration-500"
                                    leaveFrom="transform translate-x-0 opacity-100"
                                    leaveTo="transform translate-x-full opacity-0"
                                    className={`${isLastItem && "hidden"} flex gap-3 rounded-l-md bg-white rounded-lg shadow-md mb-6`}
                                >
                                    <div className={`bg-pink-400 w-[6%] rounded-l-lg text-white text-lg flex flex-col items-center justify-evenly`}>
                                        <h3 className="py-1 px-3 rounded-bl-lg">{index + 1}</h3>
                                    </div>
                                    <div className="grid grid-flow-col grid-rows-4 gap-2 w-[90%] items-center pt-4 relative">
                                        <div className="absolute top-0 right-0 flex gap-3">
                                            <button onClick={(e) => {
                                                if (isEditMode) {
                                                    removeItem(e, index);
                                                } else {
                                                    setIsOpenAlert(true);
                                                    setTypeAlert(2);
                                                    setMsgAlert("Anda harus mengaktifkan mode edit")
                                                }
                                            }} className="text-pink-500 p-2 rounded hover:text-pink-300 active:text-pink-200 text-lg">X</button>
                                        </div>
                                        <BoxArrResult label={"Jenis Sosmed"} value={findSosmed?.label} />
                                        <BoxArrResult label={typeUser === 2 ? "Nomor Telepon" : "Username"} value={item.username} />
                                    </div>
                                </Transition>
                            )
                        })
                    )}
                </ul>
            </div>
        </Transition>
    )
}