import { useState, useEffect } from "react";
import useStoreForm from "../../../../store/state";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Transition } from "@headlessui/react";
import { Form, IconSave, ModalForm, TitleForm, IconEdit } from "../components";
import { getDataQuestion } from "../../../../helper/api/getApi";
import Rating from "react-rating";

export const FormQuestion = ({ isOpenSideBar, codeQuest }) => {
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState(1); // 0 alert delete, 1 alert simpan, 2 edit
    const [isEditMode, setIsEditMode] = useState(false);
    const [msgAlert, setMsgAlert] = useState("Apakah anda sudah yakin dengan data ini?");

    const storeQuestion = useStoreForm.getState().question;

    const { control } = useForm({
        defaultValues: {
            quest: []
        },
    });

    const { fields, append, prepend } = useFieldArray({
        control,
        name: 'quest'
    })

    const watchFormQuestion = useWatch({
        control,
        name: 'quest'
    })

    useEffect(() => {
        // console.log("Code Quest", codeQuest);
        firstRender();
    }, [])

    function firstRender() {
        const _data = storeQuestion?.data;
        const isSameQuest = codeQuest === storeQuestion.code;

        if (_data.length > 0 && isSameQuest) {
            prepend(_data);
        } else {
            getDataQuestion(codeQuest)
                .then(res => {
                    prepend(res);
                })
                .catch(err => console.error("Error Question"));
            setIsEditMode(true);
        }
    }

    function saveForm() {
        // console.log(`Watch`, watchFormQuestion)
        // console.log("Filter value", fields);
        useStoreForm.setState({ question: { data: watchFormQuestion, isEdit: isEditMode, isDone: true, code: codeQuest } });
        setIsEditMode(false);
        setIsOpenAlert(false);
    }

    const routePresAlert = () => {
        switch (typeAlert) {
            case 1:
                saveForm();
                break;
            case 2:
                setIsEditMode(true);
                setIsOpenAlert(false);
                break;
            default:
                setIsOpenAlert(false);
                break;
        }
    }

    return (
        <Transition
            show={true}
            appear={true}
            enter="transition-all duration-700"
            enterFrom="transform -translate-x-full opacity-0"
            enterTo="transform translate-x-0 opacity-100"
            leave="transition-all duration-500"
            leaveFrom="transform translate-x-0 opacity-100"
            leaveTo="transform translate-x-full opacity-0"
            className="mx-auto inset-0 z-20 overflow-y-scroll flex flex-col relative"
        >
            <ModalForm
                isOpen={isOpenAlert}
                typeAlert={typeAlert}
                msgAlert={msgAlert}
                onPressFalse={() => setIsOpenAlert(false)}
                onPressTrue={routePresAlert}
            />
            {isEditMode ? (
                <div className="flex divide-x-2 divide-slate-200 gap-3">
                    <Form className={`w-full md:px-3 ${isOpenSideBar && ('hidden')} md:block gap-1 `}>
                        <TitleForm title={'Pertanyaan'} />
                        <div className="grid divide-x-2 grid-flow-row grid-cols-2 gap-2">
                            {fields.map((field, index) => {
                                return (
                                    <div key={field.id} className={`mb-2 text-xl flex flex-col items-center p-2 gap-2`}>
                                        <label htmlFor={"mantap"} className={`after:content-['*'] after:ml-0.5 after:text-red-500 font-medium text-left text-xl text-gray-800`}>{field?.pertanyaan || ""}</label>
                                        <Controller
                                            name={`quest[${index}].jawaban`}
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: { onChange, ref, value } }) => {
                                                return <Rating
                                                    ref={ref} onChange={onChange}
                                                    initialRating={value}
                                                    emptySymbol={<span className="icon-text text-xl mx-6 text-gray-700">-</span>}
                                                    fullSymbol={[1, 2, 3, 4, 5].map(n => <span className="mx-6 px-2 py-1 font-medium">{n}</span>)}
                                                />
                                            }}
                                        />
                                        {/* <Rating {...register(`quest.${index}.jawaban`)} /> */}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="flex justify-center mt-2 gap-2">
                            {fields.length > 1 && (
                                <div onClick={() => {
                                    setIsOpenAlert(true);
                                    setMsgAlert("Anda yakin dengan data yang akan di simpan ini?")
                                    setTypeAlert(1);
                                }} className="uppercase cursor-pointer bg-pink-500 hover:bg-pink-400 active:bg-pink-300 transition-colors text-white text-xs font-semibold flex gap-1.5 rounded items-center px-3 py-1.5 mt-4">
                                    <IconSave />
                                    simpan
                                </div>
                            )}
                        </div>
                    </Form>
                </div>
            ) : (
                <div className="flex flex-col items-center">
                    <button onClick={() => setIsEditMode(true)} className="text-white bg-yellow-400 w-28 flex gap-2 items-center self-end justify-center py-1 px-3 rounded mt-3">
                        <IconEdit />
                        <span>Edit</span>
                    </button>
                    <div className='text-slate-600 flex flex-col gap-2 w-full'>
                        <h1 className="flex text-lg uppercase items-center justify-center font-semibold">jawaban</h1>
                        {fields.map((value, key) => {
                            return (
                                <div className="bg-white shadow rounded-md p-3 border border-slate-300">
                                    <div className="text-lg font-semibold flex">
                                        <span>{key + 1}. </span>
                                        <h1 className="text-lg font-semibold"> {value.pertanyaan}</h1>
                                    </div>
                                    <p className="pl-3"> {value.jawaban}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </Transition>
    )
}