import { ipaddress } from "../../components/constant/ip";

export const getDetilJob = (id) => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;
                    if(status == "success") {
                        res(data)
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataSocmed = () => {
    return new Promise((res, rej) => {
        try {
            let myHeaders = new Headers();

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/socmed/get-socmed`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status == "success") {
                        const _data = result.data;
                        if (Array.isArray(_data)) {
                            let caseSosmed = [];
                            _data.map((val) => {
                                const dataSosmed = {
                                    label: val?.code_name || "",
                                    value: val?.ID || ""
                                }
                                caseSosmed.push(dataSosmed);
                            })
                            res(caseSosmed);
                        }
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataQuestion = (submissionCode) => {
    let subCode = submissionCode || "SM-31-20230224-1173171-967861510"
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/question/${subCode}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;
                    let caseStars = [];

                    if (status === "success") {
                        if (Array.isArray(data)) {
                            data.map((value) => {
                                const skeleton = {
                                    ...value,
                                    question_id: value?.ID,
                                    jawaban: 0
                                }
                                caseStars.push(skeleton);
                            })
                            res(caseStars)
                        }
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataStatusMarital = () => {
    return new Promise((res, rej) => {
        try {
            let myHeaders = new Headers();

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/status/marital`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataProvince = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/area/province`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;
                    if (status === "success") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {

        }
    })
}

export const getDataCity = (id) => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/area/city/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataRegency = (id) => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/area/district/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataFormalEdu = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/education/formal`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataJurusan = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/education/jurusan`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success" || status === "berhasil") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => console.log('error'));
        } catch (error) {

        }
    })
}

export const getDataNonFormalEdu = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/education/non-formal`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success" || status === "berhasil") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => console.log('error'));
        } catch (error) {

        }
    })
}

export const getDataPosition = () => {
    return new Promise((res, rej) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${ipaddress}/api/career/get/position`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const status = result?.status;
                const data = result?.data;

                if (status === "success" || status === "berhasil") {
                    res(data);
                } else {
                    rej(result);
                }
            })
            .catch(error => console.log('error'));
    })
}

export const getDataJenisBisnis = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/get/jenis-bisnis`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success" || status === "berhasil") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getLevelSkill = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/get/tingkat-keahlian`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success" || status === "berhasil") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataHobby = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/get/jenis-hobi`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success" || status === "berhasil") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataFamily = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/get/family`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success" || status === "berhasil") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {
            rej(error);
        }
    })
}

export const getDataFamilyEmergency = () => {
    return new Promise((res, rej) => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${ipaddress}/api/career/get/hub-kel-darurat`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const status = result?.status;
                const data = result?.data;

                if (status === "success" || status === "berhasil") {
                    res(data);
                } else {
                    rej(result);
                }
            })
            .catch(error => console.log('error'));
    })
}

export const getDataGender = () => {
    return new Promise((res, rej) => {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${ipaddress}/api/career/get/gender`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const status = result?.status;
                    const data = result?.data;

                    if (status === "success" || status === "berhasil") {
                        res(data);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (error) {

        }
    })
}

export const getDataSim = () => {
    return new Promise((res, rej) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${ipaddress}/api/career/get/jenis-sim`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const status = result?.status;
                const data = result?.data;

                if (status === "success" || status === "berhasil") {
                    res(data);
                } else {
                    rej(result);
                }
            })
            .catch(error => rej(error));
    })
}

export const getDataStatusRumah = () => {
    return new Promise((res, rej) => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${ipaddress}/api/career/get/status-rumah`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const status = result?.status;
                const data = result?.data;

                if (status === "success" || status === "berhasil") {
                    res(data);
                } else {
                    rej(result);
                }
            })
            .catch(error => rej(error));
    })
}

export const getGolDarah = () => {
    return new Promise((res, rej) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${ipaddress}/api/career/get/golongan-darah`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const status = result?.status;
                const data = result?.data;

                if (status === "success" || status === "berhasil") {
                    res(data);
                } else {
                    rej(result);
                }
            })
            .catch(error => rej(error));
    })
}