export const HomePagination = ({to, from, total, onPrevious, onNext, pageNow}) => {
    return (
        <div className="flex flex-col items-center">
            <span className="text-sm text-gray-700">
                Showing <span className="font-semibold text-gray-900">{from}</span> to <span className="font-semibold text-gray-900">{to}</span> of <span className="font-semibold text-gray-900">{total}</span> Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
                <button onClick={onPrevious} className="px-4 py-2 text-sm text-gray-800 font-semibold bg-white shadow rounded-l hover:bg-gray-300">
                    Prev
                </button>
                <button onClick={onNext} className="px-4 py-2 text-sm text-gray-800 font-semibold bg-white shadow border-0 border-l border-gray-700 rounded-r hover:bg-gray-300">
                    Next
                </button>
            </div>
        </div>

    )
}