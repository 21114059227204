import { useState, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import useStoreForm from "../../../../store/state";
import { getDataCity, getDataFamilyEmergency, getDataGender, getDataProvince, getDataRegency, getDataSim, getDataStatusMarital, getDataStatusRumah, getGolDarah } from "../../../../helper/api/getApi";
import { Transition } from "@headlessui/react";
import { Form, IconEdit, IconSave, InputForm, ResultItem, TitleForm, onHandleFile } from "../components";
import Select from 'react-select'; 

const formPersonal = {
    fullName: "",
    pasFoto: "",
    noKTP: "",
    fileCV: "",
    fotoKTP: "",
    domisiliKTP: "",
    domisiliNow: "",
    age: "",
    dateBirth: "",
    placeBirth: "",
    statusKawin: "",
    statusDomicile: "",
    totalChild: 0,
    gender: "",
    bloodType: "",
    heightBody: "",
    weightBody: "",
    province: "",
    city: "",
    regency: "",
    email: "",
    phoneNumber: "",
    phoneNumber2: "",
    emergencyPhoneNumber: "",
    ownerEmergencyPhone: "",
    statusOwnerEmergency: "",
    addressEmergency: "",
    kindOfSim: "",
}

export const RegistDataPersonal = ({ isOpenSideBar }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [dataMarital, setDataMarital] = useState([]);
    const [dataProvince, setDataProvince] = useState([]);
    const [dataCity, setDataCity] = useState([]);
    const [dataRegency, setDataRegency] = useState([]);
    const [dataSim, setDataSim] = useState([]);
    const [dataRelationFamily, setDataRelationFamily] = useState([]);
    const [dataStatusHome, setDataStatusHome] = useState([]);
    const [dataGolDarah, setDataGolDarah] = useState([]);
    const [dataGender, setDataGender] = useState([]);


    const [valueMarital, setValueMarital] = useState("NOT FOUND");
    const [valueProvince, setValueProvince] = useState("NOT FOUND");
    const [valueCity, setValueCity] = useState("NOT FOUND");
    const [valueRegency, setValueRegency] = useState("NOT FOUND");
    const [valueDomicile, setValueDomicile] = useState("NOT FOUND");
    const [valueFamily, setValueFamily] = useState("NOT FOUND");
    const [valueGender, setValueGender] = useState("NOT FOUND");
    const [valueGolDarah, setValueGolDarah] = useState("NOT FOUND");
    const [valueSIM, setValueSIM] = useState("NOT FOUND");
    const [uriPasPhoto, setUriPasPhoto] = useState("");
    const [uriCV, setUriCV] = useState("");
    const [uriKTP, setUriKTP] = useState("");

    const [isGetProv, setIsGetProv] = useState(true);
    const [isGetCity, setIsGetCity] = useState(true);
    const [isGetRegency, setIsGetRegency] = useState(true);

    const { control, handleSubmit, formState: { errors, isValid }, setValue, getValues } = useForm({
        mode: 'onChange',
        defaultValues: formPersonal
    });

    const storePersonal = useStoreForm(state => state?.personal?.data);
    const watchPersonal = useWatch({ control });
    // const statusKawin = getValues('statusKawin');

    const _province = useStoreForm.getState()?.dataProvince;
    const _city = useStoreForm.getState()?.dataCity;
    const _regency = useStoreForm.getState()?.dataRegency;
    const _gender = useStoreForm.getState()?.dataGender;
    const _golDarah = useStoreForm.getState()?.dataGolDarah;
    const _marital = useStoreForm.getState()?.dataStatusMarital;
    const _family = useStoreForm.getState()?.dataFamilyEmergency;
    const _statusRumah = useStoreForm.getState()?.dataStatusHome;
    const _sim = useStoreForm.getState()?.dataSim;

    useEffect(() => {
        const marital = getValues('statusKawin');
        const province = getValues('province');
        const city = getValues('city');
        const regency = getValues('regency');
        const family = getValues('statusOwnerEmergency');
        const gender = getValues('gender');
        const golDarah = getValues('bloodType');
        const sim = getValues('kindOfSim');
        const domicile = getValues('statusDomicile');

        // const foundItemMarital = dataMarital.find(e => e.value === marital?.value);
        // const foundItemProvince = _province.find(e => e.value === province?.value);
        // const foundItemCity = _city.find(e => e.value === city?.value);
        // const foundItemRegency = _regency.find(e => e.value === regency?.value);
        // const foundRelationEmergencyNo = dataRelationFamily.find(e => e.value === family?.value)
        // const foundGender = dataGender.find(e => e.value === gender?.value);
        // const foundBloodType = dataGolDarah.find(e => e.value === golDarah?.value);
        // const foundSIM = dataSim.find(e => e.value === sim?.value);
        // const foundDomicile = dataStatusHome.find(e => e.value === domicile?.value);

        setValueMarital(marital?.label);
        setValueProvince(province?.label);
        setValueCity(city?.label);
        setValueRegency(regency?.label);
        setValueFamily(family?.label);
        setValueGender(gender?.label);
        setValueGolDarah(golDarah?.label);
        setValueSIM(sim?.label);
        setValueDomicile(domicile?.label);
    }, [watchPersonal])

    useEffect(() => {
        firstRender();
    }, [])

    async function firstRender() {
        if (storePersonal?.fullName) {
            setFromStore();
        } else {
            setIsEditMode(true);
        }

        if (_province.length > 0) {
            setDataProvince(_province); setIsGetProv(false);
        } else {
            getDataProvince().then(res => setDataProvince(res)).catch(err => console.log("Error Province"));
        }

        if(_city.length > 0) {
            setDataCity(_city);
            setIsGetCity(false);
        }

        if(_regency.length > 0) {
            setDataRegency(_regency);
            setIsGetRegency(false);
        }

        _marital.length > 0 ? setDataMarital(_marital) : getDataStatusMarital().then(res => {
            useStoreForm.setState({ dataStatusMarital: res });
            setDataMarital(res);
        }).catch(err => console.log("error marital"));
        _sim.length > 0 ? setDataSim(_sim) : getDataSim().then(res => {
            useStoreForm.setState({ dataSim: res });
            setDataSim(res);
        }).catch(err => console.log("Error SIM"));
        _family.length > 0 ? setDataRelationFamily(_family) : getDataFamilyEmergency().then(res => {
            useStoreForm.setState({ dataFamilyEmergency: res });
            setDataRelationFamily(res);
        }).catch(err => console.log("Error relation"));
        _statusRumah.length > 0 ? setDataStatusHome(_statusRumah) : getDataStatusRumah().then(res => {
            useStoreForm.setState({ dataStatusHome: res });
            setDataStatusHome(res);
        }).catch(err => console.log("Error status home"));
        _golDarah.length > 0 ? setDataGolDarah(_golDarah) : getGolDarah().then(res => {
            useStoreForm.setState({ dataGolDarah: res });
            setDataGolDarah(res);
        })
        _gender.length > 0 ? setDataGender(_gender) : getDataGender().then(res => {
            useStoreForm.setState({ dataGender: res });
            setDataGender(res);
        }).catch(err => console.log("Error gender"));
    }

    function generateUriImg(sourceFile) {
        if (sourceFile) {
            const uriToObj = URL.createObjectURL(sourceFile);
            return uriToObj;
        } else {
            return false;
        }
    }

    function setFromStore() {

        setValue("fullName", storePersonal['fullName']);
        setValue("noKTP", storePersonal['noKTP']);
        setValue("domisiliKTP", storePersonal['domisiliKTP']);
        setValue("domisiliNow", storePersonal['domisiliNow']);
        setValue("age", storePersonal['age']);
        setValue("dateBirth", storePersonal['dateBirth']);
        setValue("placeBirth", storePersonal['placeBirth']);
        setValue("statusKawin", storePersonal['statusKawin']);
        setValue("gender", storePersonal['gender']);
        setValue("bloodType", storePersonal['bloodType']);
        setValue("heightBody", storePersonal['heightBody']);
        setValue("weightBody", storePersonal['weightBody']);
        setValue("statusDomicile", storePersonal['statusDomicile']);
        setValue("province", storePersonal['province']);
        setValue("city", storePersonal['city']);
        setValue("regency", storePersonal['regency']);
        setValue("email", storePersonal['email']);
        setValue("phoneNumber", storePersonal['phoneNumber']);
        setValue("phoneNumber2", storePersonal['phoneNumber2']);
        setValue("emergencyPhoneNumber", storePersonal['emergencyPhoneNumber']);
        setValue("ownerEmergencyPhone", storePersonal['ownerEmergencyPhone']);
        setValue("statusOwnerEmergency", storePersonal['statusOwnerEmergency']);
        setValue("addressEmergency", storePersonal['addressEmergency']);
        setValue("kindOfSim", storePersonal['kindOfSim']);

        const storeCV = storePersonal["fileCV"][0];
        const storePasPhoto = storePersonal["pasFoto"][0];
        const storePhotoKTP = storePersonal["fotoKTP"][0];

        if (storeCV) {
            setUriCV(generateUriImg(storeCV));
        }

        if (storePasPhoto) {
            setUriPasPhoto(generateUriImg(storePasPhoto))
        }

        if (storePhotoKTP) {
            setUriKTP(generateUriImg(storePhotoKTP));
        }

    }

    function getCity(id) {
        setIsGetCity(true);
        useStoreForm.setState({ dataProvince });
        const founded = dataProvince.find(e => e.value === id);
        // console.log("Found Province", founded);
        if (founded) {
            getDataCity(founded.province_id).then(res => {
                useStoreForm.setState({ dataCity: res });
                setDataCity(res);
                setIsGetCity(false);
            }).catch(err => {
                console.log("Error City");
                setIsGetCity(false);
            });
        }
    }

    function getRegency(id) {
        const founded = dataCity.find(e => e.value === id);
        if (founded) {
            getDataRegency(founded.city_id).then(res => {
                useStoreForm.setState({ dataRegency: res });
                setDataRegency(res);
                setIsGetRegency(false);
            }).catch(err => {
                console.log("Error regency");
                setIsGetRegency(false);
            });
        }
    }

    async function onHandleSubmit(data) {
        if (isValid) {
            useStoreForm.setState({ personal: { data: data, isEdit: false, isDone: true } });
        }
    }

    return (
        <Transition
            show={true}
            appear={true}
            enter="transition-all duration-700"
            enterFrom="transform -translate-x-full opacity-0"
            enterTo="transform translate-x-0 opacity-100"
            leave="transition-all duration-500"
            leaveFrom="transform translate-x-0 opacity-100"
            leaveTo="transform translate-x-full opacity-0"
            className="mx-auto inset-0 z-40 overflow-y-scroll"
        >
            <div className={`w-full md:px-3 ${isOpenSideBar && ('hidden')} md:block gap-1`}>
                <TitleForm title={"Data Pelamar"} />
                {isEditMode ? (
                    <Form className={`w-full md:px-3 ${isOpenSideBar && ('hidden')} md:block gap-1`} onSubmit={handleSubmit(onHandleSubmit)}>
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="fullName" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Nama Lengkap"} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"fullName"} />)}
                        />
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="fileCV" render={({ field: { onChange, onBlur, value, ref } }) => {
                                return <InputForm label={"File CV Pelamar"}
                                msgcondition={"max file 3mb"}
                                accept={'application/pdf'} isTypeFIle isrequired={true} onChange={e => onChange(onHandleFile(e, "CV File"))} value={value?.name} onBlur={onBlur} ref={ref} errors={errors} controlname={"fileCV"} />
                            }}
                        />
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="pasFoto" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Pas Foto"} accept={'image/jpg, image/jpeg, image/png'} isTypeFIle
                                msgcondition={"max file 3mb"}
                                isrequired={true} onChange={e => onChange(onHandleFile(e, "Pas Foto"))} onBlur={onBlur} value={value?.name} ref={ref} errors={errors} controlname={"pasFoto"} />)}
                        />
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="fotoKTP" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Foto KTP"} accept={'image/jpg, image/jpeg, image/png'}
                                msgcondition={"max file 3mb"}
                                isTypeFIle isrequired={true} onChange={e => onChange(onHandleFile(e, "Foto KTP"))} onBlur={onBlur} value={value?.name} ref={ref} errors={errors} controlname={"fotoKTP"} />)}
                        />
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="noKTP" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Nomor KTP"} type={'number'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"noKTP"} />)}
                        />
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="domisiliKTP" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Domisili KTP"} istextarea isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"domisiliKTP"} />)}
                        />
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="domisiliNow" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Domisili Sekarang"} istextarea isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"domisiliNow"} />)}
                        />
                        <div className="grid grid-flow-row lg:grid-flow-col lg:gap-3">
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="statusDomicile" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Status Tempat Tinggal"} isdropdown options={dataStatusHome} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"statusDomicile"} />)}
                            />
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="statusKawin" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Status Kawin"} isdropdown options={dataMarital} ref={ref} value={value} onBlur={onBlur} onChange={onChange} errors={errors} controlname={"statusKawin"} isrequired={true} />)}
                            />
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="totalChild" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Jumlah Anak"} type={'number'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"totalChild"} />)}
                            />
                        </div>
                        <div className="grid grid-flow-row lg:grid-flow-col lg:gap-3">
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="age" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Umur"} className="lg:col-span-1" type={'number'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"age"} />)}
                            />
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="dateBirth" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Tanggal Lahir"} className="lg:col-span-1" type={'date'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"dateBirth"} />)}
                            />
                        </div>
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="placeBirth" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Tempat Lahir"} type={'text'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"placeBirth"} />)}
                        />
                        <div className="grid grid-flow-row lg:grid-flow-col lg:gap-3">
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="heightBody" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Tinggi Badan"} className="lg:col-span-1" type={'number'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"heightBody"} />)}
                            />
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="weightBody" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Berat Badan"} className="lg:col-span-1" type={'number'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"weightBody"} />)}
                            />
                        </div>
                        <div className="grid grid-flow-row lg:grid-flow-col lg:gap-3">
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="gender" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Jenis Kelamin"} isdropdown options={dataGender} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"gender"} />)}
                            />
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="bloodType" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Golongan darah"} isdropdown options={dataGolDarah} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"bloodType"} />)}
                            />
                        </div>
                        <div className="grid grid-flow-row lg:grid-flow-col lg:col-span-3 lg:gap-3">
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="province" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Provinsi"} isdropdown options={dataProvince} isrequired={true} onChange={e => { onChange(e); getCity(e.value) }} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"province"} />
                                )}
                            />
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="city" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Kota/Kabupaten"} isdropdown options={dataCity} isrequired={true} onChange={e => { onChange(e); getRegency(e.value) }} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"city"} />)}
                            />
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="regency" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Kelurahan/Desa"} isdropdown options={dataRegency} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"regency"} />)}
                            />
                        </div>
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" }, }}
                            control={control} name="email" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Email"} type={"email"} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"email"} />)}
                        />
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="phoneNumber" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Nomor Telepon"} isphonenumber isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"phoneNumber"} />)}
                        />
                        <Controller
                            control={control} name="phoneNumber2" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Nomor Telepon 2"} msgconditioin={"Jika punya"} isphonenumber onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"phoneNumber2"} />)}
                        />
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="emergencyPhoneNumber" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Nomor Telepon Darurat"} isphonenumber isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"emergencyPhoneNumber"} />)}
                        />

                        <div className="grid grid-flow-row lg:grid-flow-col lg:col-span-3 lg:gap-3">
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="ownerEmergencyPhone" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Nama Pemilik Nomor Darurat"} type={'text'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"ownerEmergencyPhone"} />)}
                            />
                            <Controller
                                rules={{ required: { value: true, message: "Masih Kosong" } }}
                                control={control} name="statusOwnerEmergency" render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Hubungan dengan pemilik nomor darurat"} isdropdown options={dataRelationFamily} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"statusOwnerEmergency"} />)}
                            />
                        </div>
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="addressEmergency" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Alamat Darurat"} istextarea isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"addressEmergency"} />)}
                        />
                        <Controller
                            rules={{ required: { value: true, message: "Masih Kosong" } }}
                            control={control} name="kindOfSim" render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Jenis SIM"} isdropdown options={dataSim} positionSelect={'relative'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={"kindOfSim"} />)}
                        />
                        <div className="flex justify-center mt-2 gap-2">
                            <button type="submit" className="uppercase cursor-pointer bg-pink-500 hover:bg-pink-400 active:bg-pink-300 transition-colors text-white text-xs font-semibold flex gap-1.5 rounded items-center px-3 py-1.5 mt-4">
                                <IconSave />
                                simpan
                            </button>
                        </div>
                    </Form >
                ) : (
                    <div className="flex flex-col items-center">
                        <button onClick={() => {
                            setIsEditMode(true);
                            // useStoreForm.setState({ personal: { data: storePersonal, isEdit: true, isDone: false } });
                        }} className="text-white bg-yellow-500 w-28 flex gap-2 items-center self-end justify-center py-1 px-3 rounded mt-3">
                            <IconEdit />
                            <span>Edit</span>
                        </button>
                        <div className='flex flex-col divide-y md:divide-x text-slate-600 md:grid md:grid-flow-col md:grid-cols-2'>
                            <div className="col-span-1">
                                <ResultItem label={"Pas Foto"} isChildren>
                                    <img src={uriPasPhoto} alt='Pas Foto' className='w-3/4 bg-slate-200 border border-slate-400' />
                                </ResultItem>
                                <ResultItem label={"CV"} isChildren>
                                    <img src={uriCV} alt='CV' className='w-3/4 bg-slate-200 border border-slate-400' />
                                </ResultItem>
                                <ResultItem label={"Foto KTP"} isChildren>
                                    <img src={uriKTP} alt='Foto KTP' className='w-3/4 bg-slate-200 border border-slate-400' />
                                </ResultItem>
                            </div>
                            <div className='pl-3 col-span-1'>
                                <ResultItem label={"Nama Lengkap"} value={getValues("fullName")} />
                                <ResultItem label={"Nomor KTP"} value={getValues('noKTP')} />
                                <ResultItem label={"Domisili KTP"} value={getValues('domisiliKTP')} />
                                <ResultItem label={"Domisili Sekarang"} value={getValues('domisiliNow')} />
                                <ResultItem label={"Status Domisili"} value={valueDomicile} />
                                <ResultItem label={"Status Perkawinan"} value={valueMarital} />
                                <ResultItem label={"Umur"} value={getValues('age')} />
                                <ResultItem label={"Tanggal Lahir"} value={getValues('dateBirth')} />
                                <ResultItem label={"Tempat Lahir"} value={getValues('placeBirth')} />
                                <ResultItem label={"Jenis Kelamin"} value={valueGender} />
                                <ResultItem label={"Golongan Darah"} value={valueGolDarah} />
                                <ResultItem label={"Tinggi Badan"} value={getValues('heightBody')} />
                                <ResultItem label={"Berat Badan"} value={getValues('weightBody')} />
                                <ResultItem label={"Provinsi"} value={valueProvince} />
                                <ResultItem label={"Kota/Kabupaten"} value={valueCity} />
                                <ResultItem label={"Kelurahan/Desa"} value={valueRegency} />
                                <ResultItem label={"Nomor HP 1"} value={getValues('phoneNumber')} />
                                <ResultItem label={"Nomor HP 2"} value={getValues('phoneNumber2')} />
                                <ResultItem label={"Nomor HP Darurat"} value={getValues('emergencyPhoneNumber')} />
                                <ResultItem label={"Pemilik Nomor HP Darurat"} value={getValues('ownerEmergencyPhone')} />
                                <ResultItem label={"Hubungan Pemilik Nomor HP Darurat"} value={valueFamily} />
                                <ResultItem label={"Alamat Darurat"} value={getValues('addressEmergency')} />
                                <ResultItem label={"Jenis Sim"} value={valueSIM} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Transition >
    )
}