import { HiOutlineChevronRight as IconNext, HiOfficeBuilding as IconExpWork, HiChatAlt2 as IconSocmed } from "react-icons/hi"
import { ImBooks as IconEduFormal } from 'react-icons/im';
import { GiSkills as IconSkill } from 'react-icons/gi';
import { BsFillPersonBadgeFill as IconDataPerson, BsCheckCircleFill as IconChecked, BsXCircleFill as IconUnchecked } from 'react-icons/bs';
import { RiFilePaper2Fill as IconQeustion } from 'react-icons/ri';

export const MenuSidebarForm = ({ title, icon, isDisabled, onClick, isActive, isShowContent, id, statusForm, isEdit, isCompleted }) => {
    const RouteIcon = () => {
        switch (id) {
            case 0:
                return <IconDataPerson className={`text-2xl`} />
            case 1:
                return <IconEduFormal className={`text-2xl`} />
            case 2:
                return <IconExpWork className={`text-2xl`} />
            case 3:
                return <IconSkill className={`text-2xl`} />
            case 4:
                return <IconSocmed className={`text-2xl`} />
            case 5:
                return <IconQeustion className={`text-2xl`} />
            // case 6:
            //     return <IconHobby className={`text-2xl`} />
            // case 7:
            // case 8:
            //     return <IconFamily className={`text-2xl`} />
            // case 9:
            //     return <IconQeustion className={`text-2xl`} />
        }
    }

    return (
        <li className={`w-full ${isDisabled && 'border-y'}`}>
            <button onClick={onClick} className={`flex items-center justify-between px-1 py-2 w-full font-normal rounded-md transition-colors cursor-pointer text-white ${isCompleted || isEdit || isActive ? "opacity-100" : "opacity-70"} ${isActive && 'bg-pink-700'} justify-between ${!isDisabled && "hover:bg-pink-800"}`}>
                <div className="flex items-center">
                    {icon || <RouteIcon />}
                    <div className="flex ml-2 items-center gap-2">
                        {statusForm == 2 && (<IconChecked className="text-green-500 rounded-full" />)}
                        <p className={`${isShowContent ? 'block' : 'hidden'} ${isDisabled && 'text-left'} md:block`}>{title}</p>
                    </div>
                </div>
                <IconNext className={`${isDisabled && 'hidden'} ${isShowContent ? 'block' : 'hidden'} md:block text-lg`} />
            </button>
        </li>
    )
}