import { useState, useEffect } from "react";
import useStoreForm from "../../../../store/state";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { getDataFormalEdu, getDataJurusan } from "../../../../helper/api/getApi";
import { Transition } from "@headlessui/react";
import { BoxArrResult, EditScreen, Form, IconAdd, IconSave, InputForm, ModalForm, TitleForm, onHandleFile, ruleRequired, IconDate, Modal } from "../components";
import { RegistNonFormalEdu } from "./registEduNonFormal";

const formEduFormal = [
    {
        institusi: "",
        tingkat_pendidikan: "",
        jurusan: "",
        ipk: "",
        file: "",
        tahun_mulai: "",
        tahun_akhir: "",
        keterangan: ""
    },
]

export const RegistFormalEdu = ({ isOpenSideBar }) => {
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState(1); // 0 alert delete, 1 alert simpan, 2 edit
    const [isEditMode, setIsEditMode] = useState(true);
    const [msgAlert, setMsgAlert] = useState("Apakah anda sudah yakin dengan data ini?");
    const [dataFormalEdu, setDataFormalEdu] = useState([]);
    const [dataJurusan, setDataJurusan] = useState([]);
    const [isRegistNonFormal, setIsRegistNonFormal] = useState(false);

    const [selectLevetEdu, setSelectLevelEdu] = useState(null);
    const [selectMajor, setSelectMajor] = useState(null);

    const storeFormalEdu = useStoreForm(state => state.eduFormal);
    const storeNonFormalEdu = useStoreForm.getState().eduNonFormal?.data;
    const _jurusan = useStoreForm.getState()?.dataJurusan;
    const _grade = useStoreForm.getState()?.dataGrade;

    const { formState: { errors, isValid }, control, handleSubmit } = useForm({
        defaultValues: {
            formEdu: formEduFormal
        },
    });

    const { append, fields, prepend, remove } = useFieldArray({
        control,
        name: 'formEdu'
    })

    const watchEduFormal = useWatch({
        control,
        name: 'formEdu'
    })

    useEffect(() => {
        firstRender();
    }, [])

    function firstRender() {
        (Array.isArray(storeNonFormalEdu) && storeNonFormalEdu.length > 0) && setIsRegistNonFormal(true)

        _grade.length > 0 ? setDataFormalEdu(_grade) : getDataFormalEdu().then(res => {
            setDataFormalEdu(res);
            useStoreForm.setState({ dataGrade: res });
        }).catch(err => console.error("Error Formal"));

        _jurusan.length > 0 ? setDataJurusan(_jurusan) : getDataJurusan().then(res => {
            setDataJurusan(res);
            useStoreForm.setState({ dataJurusan: res });
        }).catch(err => console.log("Error Major"));

        const _data = storeFormalEdu?.data;
        const _isEdit = storeFormalEdu?.isEdit;

        Modal.setAppElement('body');
        if (Array.isArray(_data)) {
            if (_data.length == 0) {
                setIsEditMode(true);
            } else {
                prepend(_data);
                // useStoreForm.setState({ eduFormal: { data: [], isEdit: isEditMode, isDone: false } });
            }
        }
        setIsEditMode(_isEdit);
    }

    function saveToStore(mode) {
        const filterValue = watchEduFormal?.filter(value => value.institusi !== "");

        if (mode == 1) {
            useStoreForm.setState({ eduFormal: { data: filterValue, isEdit: false, isDone: true } });
        } else if (mode == 2) {
            useStoreForm.setState({ eduFormal: { data: filterValue, isEdit: true, isDone: false } });
        }
    }

    async function onHandleSubmit(data) {
        if (isValid) {
            append(formEduFormal);
            saveToStore();
            // useStoreForm.setState({ eduFormal: { data: fields, isEdit: isEditMode, isDone: false } });
        } else {
            saveToStore();
            console.log("Error Handle")
        }
    }

    function removeItem(e, index) {
        e.preventDefault();
        remove(index);
    }

    const routePresAlert = () => {
        switch (typeAlert) {
            case 1:
                saveToStore(1);
                break;
            case 2:
                setIsEditMode(true);
                setIsOpenAlert(false);
                break;
            case 3:
                useStoreForm.setState({ eduNonFormal: { isDone: true, isEdit: false, data: [] } });
                setIsOpenAlert(false);
                setIsRegistNonFormal(false);
                break;
            default:
                setIsOpenAlert(false);
                break;
        }
    }

    return (
        <>
            <Transition
                show={true}
                appear={true}
                enter="transition-all duration-700"
                enterFrom="transform -translate-x-full opacity-0"
                enterTo="transform translate-x-0 opacity-100"
                leave="transition-all duration-500"
                leaveFrom="transform translate-x-0 opacity-100"
                leaveTo="transform translate-x-full opacity-0"
                className="mx-auto inset-0 z-20 overflow-y-scroll flex flex-col relative"
            >
                <ModalForm
                    isOpen={isOpenAlert}
                    typeAlert={typeAlert}
                    msgAlert={msgAlert}
                    onPressFalse={() => setIsOpenAlert(false)}
                    onPressTrue={routePresAlert}
                />
                <TitleForm title={'Pendidikan Formal Pelamar'} />
                <div className="flex flex-col md:flex-row divide-y md:divide-x divide-slate-200 gap-3">
                    {isEditMode ? (
                        <Form className={`md:px-3 ${isOpenSideBar && ('hidden')} md:block gap-1 ${fields.length > 1 ? "w-1/2" : "w-[100%]"} relative`} onSubmit={handleSubmit(onHandleSubmit)}>
                            <div className="mt-4 flex items-center justify-end self-end gap-1.5">
                                <button onClick={() => {
                                    if (isValid) {
                                        // console.log(isValid);
                                        append(formEduFormal);
                                        saveToStore(2);
                                    }
                                }} className="uppercase bg-green-500 hover:bg-green-400 active:bg-green-300 transition-colors text-white text-xs font-semibold rounded px-3 py-1.5 flex">
                                    <IconAdd />
                                    tambah
                                </button>
                            </div>
                            <Controller
                                defaultValue={""}
                                rules={{ required: ruleRequired("Nama Institusi masih kosong") }}
                                control={control} name={`formEdu[${fields.length - 1}].institusi`}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Nama Institusi"} type={'text'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`formEdu[${fields.length - 1}].institusi`} />
                                )}
                            />
                            <div className="flex flex-col md:grid md:grid-flow-col md:gap-4">
                                <Controller
                                    defaultValue={""}
                                    rules={{ required: ruleRequired("Tingkat pendidikan masih kosong") }}
                                    control={control} name={`formEdu[${fields.length - 1}].tingkat_pendidikan`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <InputForm label={"Tingkat Pendidikan"} isdropdown options={dataFormalEdu} type={'text'} isrequired={true} onChange={e => { onChange(e.value); setSelectLevelEdu(e) }} onBlur={onBlur} value={selectLevetEdu} ref={ref} errors={errors} controlname={`formEdu[${fields.length - 1}].tingkat_pendidikan`} />
                                    )}
                                />
                                <Controller
                                    defaultValue={""}
                                    rules={{ required: ruleRequired("Jurusan pendidikan masih kosong") }}
                                    control={control} name={`formEdu[${fields.length - 1}].jurusan`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <InputForm label={"Jurusan"} isdropdown options={dataJurusan} isrequired={true} onChange={e => { onChange(e.value); setSelectMajor(e) }} onBlur={onBlur} value={selectMajor} ref={ref} errors={errors}
                                            controlname={`formEdu[${fields.length - 1}].jurusan`} />
                                    )}
                                />
                                <Controller
                                    defaultValue={""}
                                    rules={{ required: ruleRequired("Nilai Akhir masih kosong") }}
                                    control={control} name={`formEdu[${fields.length - 1}].ipk`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <InputForm label={"Nilai Akhir"} type={'number'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`formEdu[${fields.length - 1}].ipk`} />
                                    )}
                                />
                            </div>
                            <Controller
                                defaultValue={""}
                                rules={{ required: ruleRequired("File ijazah masih kosong") }}
                                control={control} name={`formEdu[${fields.length - 1}].file`}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"File Ijazah"} msgconditioin={"max file 3 mb"} accept={"application/pdf"} type={'file'} isrequired={true} onChange={e => onChange(onHandleFile(e, "File Ijazah"))} onBlur={onBlur} value={value?.name} ref={ref} errors={errors}
                                        controlname={`formEdu[${fields.length - 1}].file`} />
                                )}
                            />
                            <div className="flex items-center justify-center gap-4">
                                <div className="w-[47%]">
                                    <Controller
                                        defaultValue={""}
                                        rules={{ required: ruleRequired("Tahun Masuk masih kosong") }}
                                        control={control} name={`formEdu[${fields.length - 1}].tahun_mulai`}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <InputForm label={"Tahun Masuk"} type={'date'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors}
                                                controlname={`formEdu[${fields.length - 1}].tahun_mulai`} />
                                        )}
                                    />
                                </div>
                                <IconDate className="text-4xl text-gray-700 mt-4" />
                                <div className="w-[47%]">
                                    <Controller
                                        defaultValue={""}
                                        rules={{ required: ruleRequired("Tahun Keluar masih kosong") }}
                                        control={control} name={`formEdu[${fields.length - 1}].tahun_akhir`}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <InputForm label={"Tahun Keluar"} type={'date'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors}
                                                controlname={`formEdu[${fields.length - 1}].tahun_akhir`} />
                                        )}
                                    />
                                </div>
                            </div>
                            <Controller
                                defaultValue={""}
                                control={control} name={`formEdu[${fields.length - 1}].keterangan`}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Keterangan"} istextarea onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors}
                                        controlname={`formEdu[${fields.length - 1}].keterangan`} />
                                )}
                            />
                            <div className="flex justify-center mt-2 gap-2">
                                {/* <button type="submit" className="uppercase bg-green-500 hover:bg-green-400 active:bg-green-300 transition-colors text-white text-xs font-semibold flex gap-1.5 rounded items-center px-3 py-1.5 mt-4">
                                    <IconAdd />
                                    tambah
                                </button> */}
                                <button type="button" onClick={() => {
                                    if (isValid) {
                                        saveToStore(1);
                                    }
                                }} className="uppercase cursor-pointer bg-pink-500 hover:bg-pink-400 active:bg-pink-300 transition-colors text-white text-xs font-semibold flex gap-1.5 rounded items-center px-3 py-1.5 mt-4">
                                    <IconSave />
                                    simpan
                                </button>
                            </div>
                        </Form>
                    ) : (
                        <EditScreen isHasItem={fields.length > 1} isOpenSideBar={isOpenSideBar} onPressEdit={() => {
                            // useStoreForm.setState({ eduFormal: { data: storeFormalEdu, isEdit: true, isDone: false } });
                            setIsEditMode(true);
                        }} />
                    )}
                    <ul className={`md:${fields.length > 1 ? "w-1/2" : "hidden"} md:pl-6 overflow-y-auto h-full`}>
                        {(fields.map((item, index) => {
                            const isLastItem = index === (fields.length - 1);
                            const foundGrade = Array.isArray(_grade) && _grade.find(e => e?.value === item.tingkat_pendidikan);
                            const foundJurusan = Array.isArray(_jurusan) && _jurusan.find(e => e?.value === item.jurusan);

                            return (
                                <Transition
                                    key={index}
                                    show={true}
                                    appear={true}
                                    enter="transition-all duration-700"
                                    enterFrom="transform -translate-x-full opacity-0"
                                    enterTo="transform translate-x-0 opacity-100"
                                    leave="transition-all duration-500"
                                    leaveFrom="transform translate-x-0 opacity-100"
                                    leaveTo="transform translate-x-full opacity-0"
                                    className={`${isLastItem && "hidden"} flex gap-3 rounded-l-md bg-white rounded-lg shadow-md mb-6 mt-4 md:mt-0`}
                                >
                                    <div className={`bg-pink-400 w-[6%] rounded-l-lg text-white text-lg flex flex-col items-center justify-evenly`}>
                                        <h3 className="py-1 px-3 rounded-bl-lg">{index + 1}</h3>
                                    </div>
                                    <div className="grid grid-flow-col grid-rows-4 gap-2 w-[90%] items-center pt-4 relative">
                                        <div className="absolute top-0 right-0 flex gap-3">
                                            <button onClick={(e) => {
                                                if (isEditMode) {
                                                    removeItem(e, index);
                                                } else {
                                                    setIsOpenAlert(true);
                                                    setTypeAlert(2);
                                                    setMsgAlert("Anda harus mengaktifkan mode edit")
                                                }
                                            }} className="text-pink-500 p-2 rounded hover:text-pink-300 active:text-pink-200 text-lg">X</button>
                                        </div>
                                        <BoxArrResult label={"Nama Institut"} value={item.institusi} />
                                        <BoxArrResult label={"Tingkat Pendidikan"} value={foundGrade?.label} />
                                        <BoxArrResult label={"Jurusan"} value={foundJurusan?.label} />
                                        <BoxArrResult label={"IPK"} value={item.ipk} />
                                        <BoxArrResult label={"Ijazah"} value={item?.file[0]?.name || ""} />
                                        <BoxArrResult label={"Tahun Masuk"} value={item.tahun_mulai} />
                                        <BoxArrResult label={"Tahun Keluar"} value={item.tahun_akhir} />
                                        <BoxArrResult label={"Keterangan"} value={item.keterangan} />
                                    </div>
                                </Transition>
                            )
                        })
                        )}
                    </ul>
                </div>
                <button onClick={() => {
                    if (storeNonFormalEdu?.length > 0 && isRegistNonFormal) {
                        setIsOpenAlert(true);
                        setMsgAlert("Anda yakin ingin menghapus data Pendidikan Non Formal?")
                        setTypeAlert(3);
                    } else {
                        setIsRegistNonFormal(!isRegistNonFormal);
                    }
                }} className={`uppercase cursor-pointer ${isRegistNonFormal ? "bg-red-500 hover:bg-red-400 active:bg-red-300" : "bg-blue-500 hover:bg-blue-400 active:bg-blue-300"} transition-colors text-white text-xs font-semibold gap-1.5 rounded items-center px-3 py-1.5 my-5 flex self-end justify-center w-52`}>
                    {isRegistNonFormal ? "Batal" : "Tambah Pendidikan Non Formal"}
                </button>
            </Transition>
            {isRegistNonFormal && (
                <div className="border-t border-slate-400 pt-4">
                    <RegistNonFormalEdu />
                </div>
            )}
        </>

    )
}