import { ErrorMessage } from "@hookform/error-message"
import './formQuest.css';


export const ErrorComponent = ({labelError, errorObj}) => {
    return(
        <div className="mb-2">
            <ErrorMessage errors={errorObj} name={labelError} as={'span'} className="error" />
        </div>

    )
}