import { FloatingWhatsApp } from "react-floating-whatsapp";
import { LgLifeAtMutif } from "../../assets/img";

export const WAHelp = () => {
    return (
        <FloatingWhatsApp
            accountName="Life At Mutif"
            phoneNumber="6285926270333"
            allowEsc
            allowClickAway
            notification
            notificationSound
            avatar={LgLifeAtMutif}
            chatMessage={"Hi! ada yang bisa kami bantu?"}
        />
    )
}