import { useState, useEffect } from "react";
import useStoreForm from "../../../../store/state";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { getDataNonFormalEdu } from "../../../../helper/api/getApi";
import { Transition } from "@headlessui/react";
import { BoxArrResult, EditScreen, Form, IconAdd, IconDate, IconSave, InputForm, ModalForm, Modal, TitleForm, ruleRequired, onHandleFile } from "../components";

const formEduNonFormal = [{
    lembaga_training: "",
    jenis_training: "",
    nama_trainer: "",
    file: "",
    nomor_sertifikat: "",
    tahun_mulai: "",
    tahun_akhir: "",
    keterangan: ""
}];

export const RegistNonFormalEdu = ({ isOpenSideBar }) => {
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState(1); // 0 alert delete, 1 alert simpan, 2 edit
    const [isEditMode, setIsEditMode] = useState(true);
    const [msgAlert, setMsgAlert] = useState("Apakah anda sudah yakin dengan data ini?");
    const [dataKindEduNonFormal, setDataKindEduNonFormal] = useState([]);

    const [selectKind, setSelectKind] = useState(null);

    const storeNonFormalEdu = useStoreForm(state => state.eduNonFormal);
    const _kindTraining = useStoreForm.getState()?.dataEduNonFormal;

    const { formState: { errors, isValid }, control, handleSubmit } = useForm({
        defaultValues: {
            formEduNonFormal: formEduNonFormal
        },
    });

    const { append, fields, remove, prepend } = useFieldArray({
        control,
        name: 'formEduNonFormal'
    })

    const watchEduNonFormal = useWatch({
        control: control,
        name: 'formEduNonFormal'
    })

    useEffect(() => {
        firstRender();
    }, [])

    function firstRender() {
        _kindTraining.length > 0 ? setDataKindEduNonFormal(_kindTraining) : getDataNonFormalEdu().then(res => {
            setDataKindEduNonFormal(res);
            useStoreForm.setState({ dataEduNonFormal: res });
        }).catch(err => console.log("Error Kind Non Formal"));

        const _data = storeNonFormalEdu?.data;
        const _isEdit = storeNonFormalEdu?.isEdit;

        Modal.setAppElement('body');
        if (Array.isArray(_data)) {
            if (_data.length == 0) {
                setIsEditMode(true)
            } else {
                prepend(_data);
            }
        }
        setIsEditMode(_isEdit);
    }

    function saveToStore(mode) {
        const filterValue = watchEduNonFormal?.filter(value => value.lembaga_training !== "");

        if (mode == 1) {
            useStoreForm.setState({ eduNonFormal: { data: filterValue, isEdit: false, isDone: true } });
        } else if (mode == 2) {
            useStoreForm.setState({ eduNonFormal: { data: filterValue, isEdit: true, isDone: false } });
        }
    }

    async function onHandleSubmit(data) {
        if (isValid) {
            append(formEduNonFormal);
            saveToStore();
        } else {
            saveToStore();
            console.log("Error Handle");
        }
    }

    function removeItem(e, index) {
        e.preventDefault();
        remove(index);
    }

    const routePresAlert = () => {
        switch (typeAlert) {
            case 1:
                saveToStore(1);
                break;
            case 2:
                setIsEditMode(true);
                setIsOpenAlert(false);
                break;
            default:
                setIsOpenAlert(false);
                break;
        }
    }

    return (
        <Transition
            show={true}
            appear={true}
            enter="transition-all duration-700"
            enterFrom="transform -translate-x-full opacity-0"
            enterTo="transform translate-x-0 opacity-100"
            leave="transition-all duration-500"
            leaveFrom="transform translate-x-0 opacity-100"
            leaveTo="transform translate-x-full opacity-0"
            className="mx-auto inset-0 z-20 overflow-y-scroll flex flex-col relative"
        >
            <ModalForm
                isOpen={isOpenAlert}
                typeAlert={typeAlert}
                msgAlert={msgAlert}
                onPressFalse={() => setIsOpenAlert(false)}
                onPressTrue={routePresAlert}
            />
            <TitleForm title={'Pendidikan Non Formal Pelamar'} />
            <div className="flex divide-x-2 divide-slate-200 gap-3">
                {isEditMode || fields.length === 0 ? (
                    <Form className={`md:px-3 ${isOpenSideBar && ('hidden')} ${fields.length > 1 ? "w-1/2" : "w-[100%]"} md:block gap-1`} onSubmit={handleSubmit(onHandleSubmit)}>
                        <div className="mt-4 flex items-center justify-end self-end gap-1.5">
                            <button onClick={() => {
                                if (isValid) {
                                    append(formEduNonFormal);
                                    saveToStore(2);
                                }
                            }} className="uppercase bg-green-500 hover:bg-green-400 active:bg-green-300 transition-colors text-white text-xs font-semibold rounded px-3 py-1.5 flex">
                                <IconAdd />
                                tambah
                            </button>
                        </div>
                        <div className="flex flex-col md:grid md:grid-flow-col md:gap-3">
                            <Controller
                                rules={{ required: ruleRequired("Nama tempat pelatihan masih kosong") }}
                                control={control} name={`formEduNonFormal[${fields.length - 1}].lembaga_training`}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Nama Tempat Pelatihan"} type={'text'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`formEduNonFormal[${fields.length - 1}].lembaga_training`} />
                                )}
                            />
                            <Controller
                                rules={{ required: ruleRequired("Jenis Pelatihan masih kosong") }}
                                control={control} name={`formEduNonFormal[${fields.length - 1}].jenis_training`}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Jenis Pelatihan"} type={'text'} isdropdown options={dataKindEduNonFormal} isrequired={true} onChange={e => { onChange(e?.value); setSelectKind(e) }} onBlur={onBlur} value={selectKind} ref={ref} errors={errors} controlname={`formEduNonFormal[${fields.length - 1}].jenis_training`} />
                                )}
                            />
                        </div>
                        <Controller
                            rules={{ required: ruleRequired("Nama pelatih masih kosong") }}
                            control={control} name={`formEduNonFormal[${fields.length - 1}].nama_trainer`}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Nama Pelatih"} type={'text'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`formEduNonFormal[${fields.length - 1}].nama_trainer`} />
                            )}
                        />
                        <div className="flex flex-col md:grid md:grid-flow-col md:gap-3 md:items-end">
                            <Controller
                                rules={{ required: ruleRequired("File Sertifikat masih kosong") }}
                                control={control} name={`formEduNonFormal[${fields.length - 1}].file`}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"File Sertifikat"} isrequired={true} msgconditioin={"max file 3 mb"} type={'file'} accept={"application/pdf"} onChange={e => onChange(onHandleFile(e, "File Sertifikat"))} onBlur={onBlur} value={value?.name} ref={ref} errors={errors} controlname={`formEduNonFormal[${fields.length - 1}].file`} />
                                )}
                            />
                            <Controller
                                rules={{ required: ruleRequired("Nomor Sertifikat masih kosong") }}
                                control={control} name={`formEduNonFormal[${fields.length - 1}].nomor_sertifikat`}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Nomor Sertifikat"} type={'number'} isrequired={true} onChange={(event) => onChange(parseInt(event.target.value))} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`formEduNonFormal[${fields.length - 1}].nomor_sertifikat`} />
                                )}
                            />
                        </div>
                        <div className="flex items-center justify-between gap-4">
                            <div className="w-[47%]">
                                <Controller
                                    rules={{ required: ruleRequired("Tahun Masuk masih kosong") }}
                                    control={control} name={`formEduNonFormal[${fields.length - 1}].tahun_mulai`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <InputForm label={"Tahun Masuk"} type={'date'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors}
                                            controlname={`formEduNonFormal[${fields.length - 1}].tahun_mulai`} />
                                    )}
                                />
                            </div>
                            <IconDate className="text-4xl hidden md:block text-gray-700 mt-4" />
                            <div className="w-[47%]">
                                <Controller
                                    rules={{ required: ruleRequired("Tahun Keluar masih kosong") }}
                                    control={control} name={`formEduNonFormal[${fields.length - 1}].tahun_akhir`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <InputForm label={"Tahun Keluar"} type={'date'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors}
                                            controlname={`formEduNonFormal[${fields.length - 1}].tahun_akhir`} />
                                    )}
                                />
                            </div>
                        </div>
                        <Controller
                            control={control} name={`formEduNonFormal[${fields.length - 1}].keterangan`}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputForm label={"Keterangan"} istextarea onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors}
                                    controlname={`formEduNonFormal[${fields.length - 1}].keterangan`} />
                            )}
                        />
                        <div className="flex justify-center mt-2 gap-2">
                            <button onClick={() => {
                                if (isValid) {
                                    saveToStore(1);
                                }
                            }} className="uppercase cursor-pointer bg-pink-500 hover:bg-pink-400 active:bg-pink-300 transition-colors text-white text-xs font-semibold flex gap-1.5 rounded items-center px-3 py-1.5 mt-4">
                                <IconSave />
                                simpan
                            </button>
                        </div>
                    </Form>
                ) : (
                    <EditScreen isHasItem={fields.length > 1} isOpenSideBar={isOpenSideBar} onPressEdit={() => setIsEditMode(true)} />
                )}
                <ul className={`${fields.length > 1 ? "w-1/2" : "hidden"} pl-6 overflow-y-auto h-full`}>
                    {(fields.map((item, index) => {
                        const isLastItem = index === (fields.length - 1);
                        const foundKind = Array.isArray(_kindTraining) && _kindTraining.find(e => e?.value === item?.jenis_training)

                        return (
                            <Transition
                                key={index}
                                show={true}
                                appear={true}
                                enter="transition-all duration-700"
                                enterFrom="transform -translate-x-full opacity-0"
                                enterTo="transform translate-x-0 opacity-100"
                                leave="transition-all duration-500"
                                leaveFrom="transform translate-x-0 opacity-100"
                                leaveTo="transform translate-x-full opacity-0"
                                className={`${isLastItem && "hidden"} flex gap-3 rounded-l-md bg-white rounded-lg shadow-md mb-6`}
                            >
                                <div className={`bg-pink-400 w-[6%] rounded-l-lg text-white text-lg flex flex-col items-center justify-evenly`}>
                                    <h3 className="py-1 px-3 rounded-bl-lg">{index + 1}</h3>
                                </div>
                                <div className="grid grid-flow-col grid-rows-4 gap-2 w-[90%] items-center pt-4 relative">
                                    <div className="absolute top-0 right-0 flex gap-3">
                                        {/* <button onClick={(e) => {move(index, watchHobby.length - 1); removeItem(e, index)}} className="text-white bg-yellow-500 p-2 rounded hover:bg-yellow-300 active:bg-yellow-200"><IconEdit /></button> */}
                                        {/* <button onClick={(e) => removeItem(e, index)} className="text-white bg-red-500 p-2 rounded hover:bg-red-300 active:bg-red-200"><IconDelete /></button> */}
                                        <button onClick={(e) => {
                                            if (isEditMode) {
                                                removeItem(e, index);
                                            } else {
                                                setIsOpenAlert(true);
                                                setTypeAlert(2);
                                                setMsgAlert("Anda harus mengaktifkan mode edit")
                                            }

                                        }} className="text-pink-500 p-2 rounded hover:text-pink-300 active:text-pink-200 text-lg">X</button>
                                    </div>
                                    <BoxArrResult label={"Lembaga Pelatihan"} value={item?.lembaga_training} />
                                    <BoxArrResult label={"Jenis Pelatihan"} value={foundKind?.label} />
                                    <BoxArrResult label={"Nama Pelatih"} value={item?.nama_trainer} />
                                    <BoxArrResult label={"Sertifikat"} value={item?.file[0]?.name || ""} />
                                    <BoxArrResult label={"Nomor Sertifikat"} value={item?.nomor_sertifikat} />
                                    <BoxArrResult label={"Tahun Masuk"} value={item?.tahun_mulai} />
                                    <BoxArrResult label={"Tahun Keluar"} value={item?.tahun_akhir} />
                                    <BoxArrResult label={"Keterangan"} value={item?.keterangan} />
                                </div>
                            </Transition>
                        )
                    })
                    )}
                </ul>
            </div>
        </Transition>
    )
}