import HeroImg from '../../assets/img/IMG_Hero.png';
import { BiSearch as IconSearch } from 'react-icons/bi';
import { IoMdArrowBack as IconBack } from 'react-icons/io';


export const HeroHome = () => {
    return (
        <header style={{ background: "linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%)" }} className=" w-full flex md:h-[655px] flex-col-reverse md:flex-row items-center justify-center border-x-gray-500-300 md:px-28">
            <div className=" w-4/5 md:w-1/2 md:items-end flex flex-col  text-5xl font-semibold mt-6">
                <p className="text-3xl md:text-4xl font-semibold text-slate-800">Raih Karir Impian Mu Bersama <span className="font-bold text-pink-600 text-4xl md:text-5xl">MuTif.</span></p>
                {/* <div className="max-[753px]:hidden p-2 md:p-3 w-full lg:w-3/4 bg-gray-300 items-center mt-5 md:mt-8 rounded-lg shadow-md">
                    <div className="top-0 flex w-full items-center justify-between bg-white rounded-lg px-2 py-1 md:p-3 focus:outline outline-pink-600 md:text-xl text-lg">
                        <input type={'text'} className={' w-5/6 font-normal focus:outline-none'} placeholder={'Ketik karir impian mu....'} />
                        <span className="text-slate-500"><IconSearch /></span>
                    </div>
                </div> */}
                {/* <button className="flex mt-3 active:text-pink-600 text-slate-700">
                    <p className="text-lg">Lowongan yang tersedia</p>
                    <span className="text-3xl"><IconArrowRight /></span>
                </button> */}
            </div>
            <div className="w-3/4 md:w-1/3 mt-4">
                <img src={HeroImg} alt={"Hero"} className={`w-full`} />
            </div>
        </header>
    )
}

export const SearchBox = ({onChangeValue, onPressSearch}) => {
    return (
        <div className={`bg-slate-300 p-1 w-full rounded-2xl`}>
            <form onSubmit={onPressSearch} className='w-full flex py-2 px-3 items-center justify-between border-2 rounded-xl bg-white '>
                <input placeholder={'Ketik karir impian mu....'} onChange={onChangeValue} className={'focus:outline-none w-full'} />
                <button type='submit'>
                    <IconSearch className='text-2xl cursor-pointer' />
                </button>
            </form>
        </div>
    )
}

export const HeroDetilJob = ({ onPressBack, position, departement, jobRole, location }) => {
    const PartInfoHero = ({ label, value, className }) => {
        return (
            <div className={`flex-wrap ${className} pl-3`}>
                <h3 className='text-sm md:text-md text-zinc-300 font-semibold'>{label}</h3>
                <p className={`text-md md:text-md text-white font-bold`}>{value}</p>
            </div>
        )
    }

    return (
        <div className='bg-gradient-to-bl from-pink-400 to-pink-700 pt-16 pb-5 px-5 md:pl-[15%] md:pr-[10%]'>
            <button className='flex items-center text-white mt-5 md:text-xl' onClick={onPressBack}>
                <IconBack />
                <p className='ml-2'>kembali</p>
            </button>

            <div className='my-10'>
                <p className='text-3xl md:text-3xl font-bold text-white'>{position.toUpperCase()}</p>
            </div>

            <div className='md:w-1/2'>
                <div className='flex gap-3 rounded-lg flex-wrap divide-x'>
                    <PartInfoHero label={'Departement'} value={departement} />
                    <PartInfoHero label={'Pangkat'} value={jobRole} />
                    <PartInfoHero label={'Lokasi'} value={location} />
                </div>
            </div>
        </div>
    )
}