import useStoreForm from "../../../store/state"

export const succesSendToDB = () => {
    useStoreForm.setState({
        personal: {
            data: {},
            isEdit: true,
            isDone: false,
            label: "personal"
        },
        eduFormal: {
            data: [],
            isEdit: true,
            isDone: false,
            label: "Pendidikan Formal"
        },
        eduNonFormal: {
            data: [],
            isEdit: true,
            isDone: false,
            label: "Pendidikan NonFormal"
        },
        histJob: {
            data: [],
            isEdit: true,
            isDone: false,
            label: "Riwayat Pekerjaan"
        },
        skillLang: {
            data: [],
            isEdit: true,
            isDone: false,
            label: "Bahasa Pelamar"
        },
        skillApplicant: {
            data: [],
            isEdit: true,
            isDone: false,
            label: "Keahlian Pelamar"
        },
        hobby: {
            data: [],
            isEdit: true,
            isDone: false,
            label: "Hobi Pelamar"
        },
        socmed: {
            data: [],
            isEdit: false,
            isDone: false,
            label: "Sosial Media"
        },
        family: {
            data: [],
            isEdit: false,
            isDone: false,
            label: "Keluarga"
        },
        question: {
            data: [],
            isEdit: false,
            isDone: false,
            label: "Pertanyaan",
            code: ""
        }

    })
}