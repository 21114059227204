import { useState, useEffect } from "react";
import useStoreForm from "../../../../store/state";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Transition } from "@headlessui/react";
import { BoxArrResult, EditScreen, Form, IconAdd, IconDate, IconSave, InputForm, ModalForm, Modal, TitleForm, ruleRequired, onHandleFile, dataBool } from "../components";
import { getDataJenisBisnis, getDataPosition } from "../../../../helper/api/getApi";

const formHistJob = [{
    nama_perusahaan: "",
    jabatan_di_perusahaan: "",
    jenis_bisnis: "",
    tahun_mulai: "",
    tahun_akhir: "",
    keterangan: ""
}];

export const RegistWorkExperience = ({ isOpenSideBar }) => {
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState(1); // 0 alert delete, 1 alert simpan, 2 edit
    const [isEditMode, setIsEditMode] = useState(true);
    const [msgAlert, setMsgAlert] = useState("Apakah anda sudah yakin dengan data ini?");
    const [dataPosition, setDataPosition] = useState([]);
    const [dataJenisBisnis, setDataJenisBisnis] = useState([]);
    const [isHaveWorkExp, setIsHaveWorkExp] = useState(false);

    const [selectPosition, setSelectPosition] = useState(null);
    const [selectKindBisnis, setSelectKindBisnis] = useState(null);

    const storeHistJob = useStoreForm(state => state.histJob);
    const _position = useStoreForm.getState()?.dataPosition;
    const _jenisBisnis = useStoreForm.getState()?.dataJenisBisnis;

    const { formState: { errors, isValid }, control, handleSubmit } = useForm({
        defaultValues: {
            formHistJob: formHistJob
        },
    });

    const { fields, remove, append, prepend } = useFieldArray({
        control: control,
        name: 'formHistJob'
    })

    const watchWorkExperience = useWatch({
        control: control,
        name: 'formHistJob'
    })

    useEffect(() => {
        firstRender();
    }, [])

    function firstRender() {
        _position.length > 0 ? setDataPosition(_position) : getDataPosition().then(res => {
            useStoreForm.setState({ dataPosition: res });
            setDataPosition(res);
        }).catch(err => console.log("Error Position"));
        _jenisBisnis.length > 0 ? setDataJenisBisnis(_jenisBisnis) : getDataJenisBisnis().then(res => {
            useStoreForm.setState({ dataJenisBisnis: res });
            setDataJenisBisnis(res);
        }).catch(err => console.log("Error Jenis"));

        const _data = storeHistJob?.data;
        const _isEdit = storeHistJob?.isEdit;

        Modal.setAppElement('body');
        if (Array.isArray(_data)) {
            if (_data.length == 0) {
                setIsEditMode(true);
            } else {
                prepend(_data);
                setIsHaveWorkExp(true);
            }
        }

        setIsEditMode(_isEdit);
    }

    function saveToStore(mode) {
        const filterValue = watchWorkExperience?.filter(value => value.nama_perusahaan !== "");

        if (mode == 1) {
            useStoreForm.setState({ histJob: { data: filterValue, isEdit: false, isDone: true } });
        } else if (mode == 2) {
            useStoreForm.setState({ histJob: { data: filterValue, isEdit: true, isDone: false } });
        }
    }


    async function onHandleSubmit(data) {
        if (isValid) {
            append(formHistJob);
        } else {
            console.log("Error Handle")
        }
    }

    function removeItem(e, index) {
        e.preventDefault();
        remove(index);
    }

    function saveForm() {
        const filterValue = fields.filter(value => value.nama_perusahaan !== "");
        useStoreForm.setState({ histJob: { data: filterValue, isEdit: isEditMode, isDone: true } });
        setIsEditMode(false);
        setIsOpenAlert(false);
    }

    const routePresAlert = () => {
        switch (typeAlert) {
            case 1:
                saveToStore(1);
                break;
            case 2:
                setIsEditMode(true);
                setIsOpenAlert(false);
                break;
            default:
                setIsOpenAlert(false);
                break;
        }
    }

    function onHaveWorkExp(e) {
        if (e == "true") {
            setIsHaveWorkExp(true);
        } else {
            setIsHaveWorkExp(false);
        }
    }

    return (
        <>
            <Transition
                show={true}
                appear={true}
                enter="transition-all duration-700"
                enterFrom="transform -translate-x-full opacity-0"
                enterTo="transform translate-x-0 opacity-100"
                leave="transition-all duration-500"
                leaveFrom="transform translate-x-0 opacity-100"
                leaveTo="transform translate-x-full opacity-0"
                className="mx-auto inset-0 z-40 overflow-y-scroll"
            >
                <ModalForm
                    isOpen={isOpenAlert}
                    typeAlert={typeAlert}
                    msgAlert={msgAlert}
                    onPressFalse={() => setIsOpenAlert(false)}
                    onPressTrue={routePresAlert}
                />
                <TitleForm title={'Pengalaman Kerja'} />
                <div className="md:px-3 mb-3">
                    <div className={`mb-2`}>
                        <label className={`after:content-['*'] after:ml-0.5 after:text-red-500 font-medium`}>Punya pengalaman Kerja?</label>
                    </div>
                    <select id={"Punya pengalaman Kerja?"} value={isHaveWorkExp} onChange={e => onHaveWorkExp(e.target.value)} className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5">
                        {Array.isArray(dataBool) && dataBool.map((val, key) => <option key={key} value={val.value}>{val.label}</option>)}
                    </select>
                </div>
                {isHaveWorkExp && (
                <div className="flex flex-col md:flex-row divide-y md:divide-x divide-slate-200 gap-3">
                        {isEditMode || fields.length === 0 ? (
                            <Form className={`md:px-3 ${isOpenSideBar && ('hidden')} md:block gap-1 ${fields.length > 1 ? "w-1/2" : "w-full"}`} onSubmit={handleSubmit(onHandleSubmit)}>
                                <div className="mt-4 flex items-center justify-end self-end gap-1.5">
                                    <button onClick={() => {
                                        if (isValid) {
                                            append(formHistJob);
                                            saveToStore(2);
                                        }
                                    }} className="uppercase bg-green-500 hover:bg-green-400 active:bg-green-300 transition-colors text-white text-xs font-semibold rounded px-3 py-1.5 flex">
                                        <IconAdd />
                                        tambah
                                    </button>
                                </div>
                                <div className="flex flex-col md:grid md:grid-flow-col gap-3 md:items-end">
                                    <Controller
                                        rules={{ required: ruleRequired("Nama Perushaan masih kosong") }}
                                        control={control} name={`formHistJob[${fields.length - 1}].nama_perusahaan`}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <InputForm label={"Nama Perusahaan"} type={'text'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`formHistJob[${fields.length - 1}].nama_perusahaan`} />
                                        )}
                                    />
                                    <Controller
                                        rules={{ required: ruleRequired("Jabatan masih kosong") }}
                                        control={control} name={`formHistJob[${fields.length - 1}].jabatan_di_perusahaan`}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <InputForm label={"Jabatan"} isdropdown options={dataPosition} isrequired={true} onChange={e => { onChange(e.value); setSelectPosition(e) }} onBlur={onBlur} value={selectPosition} ref={ref} errors={errors} controlname={`formHistJob[${fields.length - 1}].jabatan_di_perusahaan`} />
                                        )}
                                    />
                                    <Controller
                                        rules={{ required: ruleRequired("Jenis Bisnis masih kosong") }}
                                        control={control} name={`formHistJob[${fields.length - 1}].jenis_bisnis`}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <InputForm label={"Jenis Bisnis"} isdropdown options={dataJenisBisnis} isrequired={true} onChange={e => { onChange(e.value); setSelectKindBisnis(e) }} onBlur={onBlur} value={selectKindBisnis} ref={ref} errors={errors} controlname={`formHistJob[${fields.length - 1}].jenis_bisnis`} />
                                        )}
                                    />
                                </div>
                                <div className="flex items-center justify-center gap-4">
                                    <div className="w-[47%]">
                                        <Controller
                                            rules={{ required: ruleRequired("Tahun Masuk masih kosong") }}
                                            control={control} name={`formHistJob[${fields.length - 1}].tahun_mulai`}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <InputForm label={"Tahun Masuk"} type={'date'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors}
                                                    controlname={`formHistJob[${fields.length - 1}].tahun_mulai`} />
                                            )}
                                        />
                                    </div>
                                    <IconDate className="hidden md:block text-4xl text-gray-700 mt-4" />
                                    <div className="w-[47%]">
                                        <Controller
                                            rules={{ required: ruleRequired("Tahun Keluar masih kosong") }}
                                            control={control} name={`formHistJob[${fields.length - 1}].tahun_akhir`}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <InputForm label={"Tahun Keluar"} type={'date'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors}
                                                    controlname={`formHistJob[${fields.length - 1}].tahun_akhir`} />
                                            )}
                                        />
                                    </div>
                                </div>
                                <Controller
                                    control={control} name={`formHistJob[${fields.length - 1}].keterangan`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <InputForm label={"Keterangan"} istextarea onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors}
                                            controlname={`formHistJob[${fields.length - 1}].keterangan`} />
                                    )}
                                />
                                <div className="flex justify-center mt-2 gap-2">
                                    <button onClick={() => {
                                        if (isValid) {
                                            saveToStore(1);
                                        }
                                    }} className="uppercase cursor-pointer bg-pink-500 hover:bg-pink-400 active:bg-pink-300 transition-colors text-white text-xs font-semibold flex gap-1.5 rounded items-center px-3 py-1.5 mt-4">
                                        <IconSave />
                                        simpan
                                    </button>
                                </div>
                            </Form>
                        ) : (
                            <EditScreen titleForm={"Pengalaman Kerja"} isHasItem={fields.length > 1} isOpenSideBar={isOpenSideBar} onPressEdit={() => setIsEditMode(true)} />
                        )}
                        <ul className={`md:${fields.length > 1 ? "w-1/2" : "hidden"} md:pl-6 overflow-y-auto h-full`}>
                            {(Array.isArray(fields) && fields.length > 1) && (
                                fields.map((item, index) => {
                                    const isLastItem = index === (fields.length - 1);
                                    const foundJabatan = _position.find(e => e.value == item.jabatan_di_perusahaan);
                                    const foundJenisBisnis = _jenisBisnis.find(e => e.value == item.jenis_bisnis);

                                    return (
                                        <Transition
                                            key={index}
                                            show={true}
                                            appear={true}
                                            enter="transition-all duration-700"
                                            enterFrom="transform -translate-x-full opacity-0"
                                            enterTo="transform translate-x-0 opacity-100"
                                            leave="transition-all duration-500"
                                            leaveFrom="transform translate-x-0 opacity-100"
                                            leaveTo="transform translate-x-full opacity-0"
                                            className={`${isLastItem && "hidden"} flex gap-3 rounded-l-md bg-white rounded-lg shadow-md mb-6 mt-4 md:mt-0`}
                                        >
                                            <div className={`bg-pink-400 w-[6%] rounded-l-lg text-white text-lg flex flex-col items-center justify-evenly`}>
                                                <h3 className="py-1 px-3 rounded-bl-lg">{index + 1}</h3>
                                            </div>
                                            <div className="grid grid-flow-col grid-rows-4 gap-2 w-[90%] items-center pt-4 relative">
                                                <div className="absolute top-0 right-0 flex gap-3">
                                                    <button onClick={(e) => {
                                                        if (isEditMode) {
                                                            removeItem(e, index);
                                                        } else {
                                                            setIsOpenAlert(true);
                                                            setTypeAlert(2);
                                                            setMsgAlert("Anda harus mengaktifkan mode edit")
                                                        }

                                                    }} className="text-pink-500 p-2 rounded hover:text-pink-300 active:text-pink-200 text-lg">X</button>
                                                </div>
                                                <BoxArrResult label={"Nama Perusahaan"} value={item.nama_perusahaan} />
                                                <BoxArrResult label={"Jabatan"} value={foundJabatan?.label} />
                                                <BoxArrResult label={"Jenis Bisnis"} value={foundJenisBisnis?.label} />
                                                <BoxArrResult label={"Tahun Masuk"} value={item.tahun_mulai} />
                                                <BoxArrResult label={"Tahun Keluar"} value={item.tahun_akhir} />
                                                <BoxArrResult label={"Keterangan"} value={item.keterangan} />
                                            </div>
                                        </Transition>
                                    )
                                })
                            )}
                        </ul>
                    </div>
                )}
            </Transition>
        </>
    )
}