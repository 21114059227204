import { ipaddress } from "../../../components/constant/ip";

export const getAllDataJob = (deptFilter, rankFilter, locFilter, searchJob, pageJob) => {
    return new Promise((resolve, reject) => {
        try {
            const URL = `${ipaddress}/api/career?${pageJob ? `&page=${pageJob}` : ""}${searchJob ? `&search=${searchJob}` : ""}${Array.isArray(deptFilter) && deptFilter.length > 0 ? `&department=${deptFilter}` : ""}${Array.isArray(rankFilter) && rankFilter.length > 0 ? `&rank=${rankFilter}` : ""}${Array.isArray(locFilter) && locFilter.length > 0 ? `&lokasi=${locFilter}` : ""}`;
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                })
                .catch(error => reject(error));
        } catch (e) {
            reject(e);
        }
    })
}

export const getDataPangkat = () => {
    return new Promise((res, rej) => {
        try {
            const URL = `${ipaddress}/api/pangkat`;
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        res(result);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (e) {
            rej(e);
        }
    })
}

export const getDataDept = () => {
    return new Promise((res, rej) => {
        try {
            const URL = `${ipaddress}/api/departemen`;
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        res(result);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (e) {
            rej(e);
        }
    })
}

export const getDataLoc = () => {
    return new Promise((res, rej) => {
        try {
            const URL = `${ipaddress}/api/lokasi`;
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        res(result);
                    } else {
                        rej(result);
                    }
                })
                .catch(error => rej(error));
        } catch (e) {
            rej(e);
        }
    })
}

export const getDataJobDetil = (id) => {
    return new Promise((res, rej) => {
        try {
            if (id) {
                const url = `${ipaddress}/api/career/${id}`;
                let requestOptions = {
                    method: 'GET',
                    redirect: 'follow'
                };

                fetch(url, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        const status = result.status;
                        if (status === "success") {
                            res(result);
                        } else {
                            rej(result);
                        }
                    })
                    .catch(error => {
                        rej(error);
                    });
            } else {
                rej('Id Job not found');
            }
        } catch (error) {
            rej(error);
        }
    })
}