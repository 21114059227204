import { useState, useEffect } from 'react';
import Navbar from '../../components/navbar/navbar';
import { useParams, useNavigate } from 'react-router-dom';
import { MainDrawer } from "../../components/drawer/drawer";
import { HeroDetilJob } from '../../components/hero/hero';
import "react-datepicker/dist/react-datepicker.css";
import { FooterMain } from '../../components/footer/footer';
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import { MenuSidebarForm } from '../../components/sidebar/formRegist';
import { BsChevronRight as IconRight, BsCaretRightFill as IconMenuRight } from 'react-icons/bs';
import useStoreForm from '../../store/state';
import { ToastContainer, toast } from 'react-toastify';
import { Collapse } from 'react-collapse';
import { ipaddress } from '../../components/constant/ip';
import { RiSendPlane2Fill as IconSend } from 'react-icons/ri';
import { getDetilJob } from '../../helper/api/getApi';
import { OverlayLoading } from '../../components/loading';
import { RegistDataPersonal, RegistFormalEdu, RegistLanguageApplicant, RegistSocmed, RegistWorkExperience, FormQuestion } from './component/form';
import { succesSendToDB } from './controller/detilJobController';
import { WAHelp } from '../../components/btn/btn';

const DetilJob = () => {
    const [isOpenDrawer, setisOpenDrawer] = useState(false);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isOpenSideBar, setIsOpenSideBar] = useState(false);
    const [dataJobDetil, setDataJobDetil] = useState(null);
    const [indexForm, setIndexForm] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dataJobDesc, setDataJobDesc] = useState([]);
    const [isLoadingSend, setIsLoadingSend] = useState(false);

    const dataPersonal = useStoreForm(state => state.personal);;
    const dataEduFormal = useStoreForm(state => state.eduFormal);
    const dataEduNonFormal = useStoreForm(state => state.eduNonFormal);
    const dataWorkExp = useStoreForm(state => state.histJob);
    const dataSkills = useStoreForm(state => state.skillApplicant);
    const dataLanguage = useStoreForm(state => state.skillLang);
    const dataFamilies = useStoreForm(state => state.family);
    const dataHobby = useStoreForm(state => state.hobby);
    const dataSocmed = useStoreForm(state => state.socmed);
    const dataQuestion = useStoreForm(state => state.question);

    const params = useParams();
    const navigate = useNavigate();


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '100%',
            width: '90%',
            padding: 0,
            backgroundColor: 'white',
            overflow: 'hidden'
        },
        overlay: {
            backgroundColor: isOpenForm ? 'rgba(1, 1, 1, 0.5)' : 'transparent',
        }
    };

    useEffect(() => {
        firstRender();
    }, [])

    // useEffect(() => {
    // }, [dataPersonal, dataEduFormal, dataEduNonFormal, dataWorkExp, dataSkills, dataLanguage, dataFamilies, dataHobby, dataSocmed, dataQuestion])

    function firstRender() {
        setIsLoading(true);
        const idJob = params?.id || null;
        if (idJob) {
            // console.log("Dapat id Job", idJob)
            getDetilJob(idJob).then(res => {
                // console.log("Detil Job");
                // console.log(res);
                setDataJobDetil(res);
                setIsLoading(false);
                onJobDesc(res?.deskripsi);
            }).catch(err => {
                navigate("/not_found", { replace: true });
                setIsLoading(false);
                // console.log(err);
            });
        } else {
            // console.log(`Params`, params)
            toast("Parameter Doesn't exist", { type: "warning", autoClose: 2000 })
            navigate("/not_found", { replace: true })
            setIsLoading(false);
        }
    }

    const RouteForm = () => {
        switch (indexForm) {
            case 0:
                return <RegistDataPersonal />
            case 1:
                return <RegistFormalEdu />
            case 2:
                return <RegistWorkExperience />
            case 3:
                return <RegistLanguageApplicant />
            case 4:
                return <RegistSocmed />
            case 5:
                return <FormQuestion codeQuest={dataJobDetil?.kode_pengajuan} />
            default:
                setIndexForm();
                break;
        }
    }

    function detectBackBtn() {
        setIsOpenForm(true);
    }

    function toggleModal() {
        setIsOpenForm(!isOpenForm)
    };

    function onJobDesc(data) {
        if (data) {
            const splitData = data.split("\n");
            // console.log("Split Data", splitData);
            setDataJobDesc(splitData);
        }
    }

    function isOnEdit(idPage) {
        // if (idPage) {
        const storeData = [dataPersonal, dataEduFormal, dataWorkExp, dataLanguage, dataSocmed];
        let caseData = [];

        for (let i = 0; i <= idPage; i++) {
            // console.log(storeData[i - 1]);
            if ((storeData[i - 1]) && (!storeData[i - 1]?.isDone || storeData[i - 1]?.isEdit)) {
                caseData.push(storeData[i - 1]);
            }
        }

        // console.log(caseData);

        if (caseData.length === 0) {
            // toast.warn(`Jangan lupa disimpan`);
            setIndexForm(idPage);
        } else {
            caseData.map((value) => toast.warn(`${value?.label} belum disimpan`))
        }
        // }
    }

    const ContenSideBar = () => {
        const [isShowFormAplicant, setIsShowFormAplicant] = useState(true);
        const [isShowQuest, setIsShowQuest] = useState(true);

        return (
            <aside className="w-full h-screen" aria-label="Sidebar">
                <ul className="space-y-2 flex flex-col divide-y">
                    <li className='ReactCollapse--collapse'>
                        <div onClick={(e) => { e.preventDefault(); setIsShowFormAplicant(!isShowFormAplicant); }} className='hidden md:flex text-white cursor-pointer mb-1 gap-3 items-center p-2 hover:bg-pink-700 active:bg-pink-800 rounded'>
                            <IconMenuRight className={`hidden md:block text-lg ${isShowFormAplicant ? "rotate-90" : "rotate-0"} transition-all`} />
                            <h3 className='hidden md:flex'>Data Pelamar</h3>
                        </div>
                        <div className='flex flex-col items-center md:items-stretch w-full'>
                            <Collapse isOpened={isShowFormAplicant} className={`ReactCollapse--content`}>
                                {dataKelengkapan.map((value, key) => {
                                    const id = value.id;
                                    const storeData = id === 0 ? dataPersonal : id === 1 ? dataEduFormal : id === 2 ? dataWorkExp : id === 3 ? dataLanguage : dataSocmed;

                                    return (
                                        <div className='mb-2 pl-2 pt-1 w-full'>
                                            <MenuSidebarForm key={key} title={value.nameTab} isCompleted={storeData?.isDone || storeData?.data?.length > 0} isActive={id === indexForm} isChecked={true} isShowContent={isOpenSideBar} id={value.id} onClick={() => { isOnEdit(value.id, storeData) }} />
                                        </div>
                                    )
                                })}
                            </Collapse>
                        </div>
                    </li>
                    <li className='ReactCollapse--collapse'>
                        <div onClick={(e) => { e.preventDefault(); setIsShowQuest(!isShowQuest); }} className='hidden md:flex text-white cursor-pointer mb-1 gap-3 items-center p-2 hover:bg-pink-700 active:bg-pink-800 rounded'>
                            <IconMenuRight className={`hidden md:block text-lg ${isShowQuest ? "rotate-90" : "rotate-0"} transition-all`} />
                            <h3 className='hidden md:block'>Pertanyaan</h3>
                        </div>
                        <div className='flex flex-col items-center md:items-stretch w-full'>
                            <Collapse isOpened={isShowQuest} className={`ReactCollapse--content`}>
                                <div className='mb-2 pl-2 pt-1'>
                                    <MenuSidebarForm title={"Pertanyaan"} isCompleted={dataQuestion?.isDone} isActive={indexForm === 5} isChecked={true} isShowContent={isOpenSideBar} id={5} onClick={() => { isOnEdit(5, dataQuestion) }} />
                                </div>
                            </Collapse>
                        </div>
                    </li>
                    <li>
                        <div onClick={(e) => { e.preventDefault(); validationSend(e); }} className='text-white cursor-pointer flex gap-3 items-center justify-center md:justify-start p-2 hover:bg-pink-700 active:bg-pink-800 rounded'>
                            <IconSend className={`text-lg transition-all`} />
                            <h3 className={`hidden md:block`}>Kirim</h3>
                        </div>
                    </li>
                </ul>
            </aside>
        )
    }

    function progEduFormal() {
        return new Promise((res, rej) => {
            try {
                const _data = dataEduFormal?.data;
                let caseEduFormal = [];
                if (Array.isArray(_data)) {
                    _data.map((value, key) => {
                        const fileStore = value?.file || null;
                        // console.log("File", fileStore)
                        if (fileStore) {
                            caseEduFormal.push(fileStore);
                        }
                    })
                    // console.log(caseEduFormal);
                    res(caseEduFormal);
                } else {
                    rej("Error Edu Formal")
                }
            } catch (error) {
                rej(error)
            }
        })
    }

    function progEduNonFormal() {
        return new Promise((res, rej) => {
            try {
                let caseEduNonFormal = [];
                const _data = dataEduNonFormal?.data;
                if (Array.isArray(_data)) {
                    _data.map((value) => {
                        const fileStore = value?.file || null
                        // console.log("File", fileStore)
                        if (fileStore) {
                            caseEduNonFormal.push(fileStore);
                        }
                    })
                    res(caseEduNonFormal);
                } else {
                    rej("Error Edu Formal")
                }
            } catch (error) {
                rej(error)
            }
        })
    }

    function validationSend(element) {
        const dataStores = [dataPersonal, dataEduFormal, dataLanguage, dataSocmed, dataQuestion];

        const filterDone = dataStores.filter(e => e?.isDone === false);
        if (filterDone.length > 0) {
            dataStores.map((value, key) => {
                if (!value?.isDone) {
                    toast.warn(`${value?.label} belum selesai`)
                }
            })
        } else {
            sendData(element);
        }
    }

    async function sendData(e) {
        e.preventDefault();
        setIsLoadingSend(true);

        let caseEduFormal = [];
        let caseEduNonFormal = [];
        await progEduFormal().then(res => caseEduFormal = res).catch(err => console.log("Error Prog Edu Formal"));
        await progEduNonFormal().then(res => caseEduNonFormal = res).catch(err => console.log("Error Prog Edu Non Formal"));

        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        let formdata = new FormData();
        formdata.append("kode_pengajuan", dataJobDetil?.kode_pengajuan);
        formdata.append("name", dataPersonal?.data?.fullName);
        formdata.append("cv", dataPersonal?.data?.fileCV ? dataPersonal?.data?.fileCV[0] : []);
        formdata.append("foto_ktp", dataPersonal?.data?.fotoKTP ? dataPersonal?.data?.fotoKTP[0] : []);
        formdata.append("foto", dataPersonal?.data?.pasFoto ? dataPersonal?.data?.pasFoto[0] : []);
        formdata.append("tinggi", dataPersonal?.data?.heightBody);
        formdata.append("berat_badan", dataPersonal?.data?.weightBody);
        formdata.append("status_rumah", dataPersonal?.data?.statusDomicile?.value || dataPersonal?.data?.statusDomicile);
        formdata.append("jenis_kelamin", dataPersonal?.data?.gender?.value || dataPersonal?.data?.gender);
        formdata.append("status_perkawinan", dataPersonal?.data?.statusKawin?.value || dataPersonal?.data?.statusKawin);
        formdata.append("jumlah_anak", dataPersonal?.data?.totalChild);
        formdata.append("no_ktp", dataPersonal?.data?.noKTP);
        formdata.append("email_pribadi", dataPersonal?.data?.email);
        formdata.append("tempat_lahir", dataPersonal?.data?.placeBirth);
        formdata.append("tanggal_lahir", dataPersonal?.data?.dateBirth);
        formdata.append("gol_darah", dataPersonal?.data?.bloodType?.value || dataPersonal?.data?.bloodType);
        formdata.append("no_whatsapp_1", dataPersonal?.data?.phoneNumber);
        formdata.append("no_whatsapp_2", dataPersonal?.data?.phoneNumber2);
        formdata.append("no_darurat", dataPersonal?.data?.emergencyPhoneNumber);
        formdata.append("atas_nama_no_darurat", dataPersonal?.data?.ownerEmergencyPhone);
        formdata.append("alamat_darurat", dataPersonal?.data?.addressEmergency);
        formdata.append("hubungan_dengan_anda", dataPersonal?.data?.statusOwnerEmergency?.value || dataPersonal?.data?.statusOwnerEmergency);
        formdata.append("sim_yang_dimiliki", dataPersonal?.data?.kindOfSim?.value || dataPersonal?.data?.kindOfSim);
        formdata.append("domisili_ktp", dataPersonal?.data?.domisiliKTP);
        formdata.append("alamat_saat_ini", dataPersonal?.data?.domisiliNow);
        formdata.append("province_id", dataPersonal?.data?.province?.value || dataPersonal?.data?.province);
        formdata.append("unit_section_id", dataJobDetil?.unit_section_id);
        formdata.append("city_id", dataPersonal?.data?.city?.value || dataPersonal?.data?.city);
        formdata.append("district_id", dataPersonal?.data?.regency?.value || dataPersonal?.data?.regency);
        formdata.append("educations", JSON.stringify(dataEduFormal?.data));
        caseEduFormal.length > 0 ? caseEduFormal.map((value) => { formdata.append("educationFile[]", value[0]) }) : formdata.append("educationFile[]", JSON.stringify([]));
        formdata.append("nonFormalEducations", dataEduNonFormal?.data?.length > 0 ? JSON.stringify(dataEduNonFormal?.data) : JSON.stringify([]));
        caseEduNonFormal.length > 0 ? caseEduNonFormal.map((value) => { formdata.append("nonFormalEducationFile[]", value[0]) }) : formdata.append("nonFormalEducationFile[]", /* JSON.stringify([]) */ null);
        formdata.append("experiences", JSON.stringify(dataWorkExp?.data));
        formdata.append("skills", JSON.stringify(dataSkills?.data));
        formdata.append("language", JSON.stringify(dataLanguage?.data));
        formdata.append("families", JSON.stringify(dataFamilies?.data));
        formdata.append("hobbies", JSON.stringify(dataHobby?.data));
        formdata.append("socmed", JSON.stringify(dataSocmed?.data));
        formdata.append("questions", JSON.stringify(dataQuestion?.data));

        // console.log("formdata", formdata);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        };

        // console.log("Loading Send...")
        fetch(`${ipaddress}/api/career/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const status = result?.status;
                if (status === "success") {
                    toast.success(result?.message);
                    succesSendToDB();
                    setIsOpenForm(false);
                    setIsLoadingSend(false);
                } else {
                    toast.warn(result?.message);
                    // console.log(result);
                    setIsLoadingSend(false);
                }
            })
            .catch(error => {
                toast.error(error?.message)
                setIsLoadingSend(false);
            });
    }

    return (
        <div>
            <OverlayLoading isLoading={isLoading} bgOpacity={"bg-opacity-100"} />
            <Navbar isChangeColorMenuButton onOpenMenu={() => setisOpenDrawer(true)} />
            <MainDrawer isOpen={isOpenDrawer} onClose={() => setisOpenDrawer(false)} />
            <ToastContainer />
            <CSSTransition
                in={isOpenForm}
                timeout={300}
                classNames="dialog"
            >
                <Modal
                    closeTimeoutMS={500}
                    isOpen={isOpenForm}
                    style={customStyles}
                    onRequestClose={toggleModal}
                    ariaHideApp={false}
                >
                    <OverlayLoading isLoading={isLoadingSend} bgOpacity={"bg-opacity-50"} />
                    <button className='absolute top-1 right-2 text-lg text-pink-600' onClick={() => setIsOpenForm(false)}>X</button>
                    <div className='flex gap-3 pr-3 h-full w-full'>
                        <div className={`bg-pink-600 pt-10 px-2 z-30 h-full relative ${isOpenSideBar ? "w-full" : "w-[18%]"} `}>
                            <button onClick={() => setIsOpenSideBar(!isOpenSideBar)} className='lg:hidden absolute top-2 -right-4 bg-white shadow-sm border-slate-200 border-2 p-1.5 rounded-full cursor-pointer'>
                                <IconRight className={`text-lg text-pink-600 ${isOpenSideBar ? 'rotate-180' : 'rotate-0'} transition-all `} />
                            </button>
                            <ContenSideBar />
                        </div>
                        <div className={`w-[85%] p-3 md:block h-full overflow-x-auto ${isOpenSideBar && 'fixed md:inline-block w-[100%]'} transition-all`}>
                            <RouteForm />
                            <div className='flex justify-center gap-3 my-3 pt-3 border-t border-slate-400'>
                                {indexForm > 0 && (<button onClick={() => isOnEdit(indexForm - 1)} className='p-2 w-20 bg-red-500 hover:bg-red-600 active:bg-red-700 font-semibold text-white rounded-md'>Previous</button>)}
                                {indexForm < dataKelengkapan.length && (<button onClick={() => isOnEdit(indexForm + 1)} className='p-2 w-20 bg-green-500 hover:bg-green-600 active:bg-green-700 font-semibold text-white rounded-md'>Next</button>)}
                            </div>
                        </div>
                    </div>
                </Modal>
            </CSSTransition>
            <HeroDetilJob position={dataJobDetil?.posisi || "KOSONG"} departement={dataJobDetil?.department_title || "KOSONG"} jobRole={dataJobDetil?.jabatan || "KOSONG"} location={dataJobDetil?.lokasi || "KOSONG"} onPressBack={() => navigate(-1)} />
            <section className='p-4 md:pl-[15%] md:pr-[10%]'>
                <div>
                    <p className='text-zinc-700'>{dataJobDetil?.pengetahuan_dan_keahlian || "KOSONG"}</p>
                </div>
                <div className='mt-5'>
                    <h2 className='mb-3 text-xl font-semibold text-zinc-900'>Deskripsi Pekejaan</h2>
                    <div className='list-disc pr-4 text-zinc-700 text-md'>
                        {dataJobDesc.length > 0 && dataJobDesc.map((value, index) => {
                            return <p key={index}>{value}</p>
                        })}
                        {/* <p>{dataJobDetil?.deskripsi}</p> */}
                        {/* <li>Handle and support the Seller Support team</li>
                        <li>Monitor inbound tickets and solve it within Service-level agreement</li>
                        <li>Monitor agents Key Performance Indicator (KPI)</li>
                        <li>Plan manpower needs</li>
                        <li>Review and solve feedbacks from sellers</li> */}
                    </div>
                </div>
                <div className='mt-5'>
                    <h2 className='mb-3 text-xl font-semibold text-zinc-900'>Persyaratan</h2>
                    <ul className='list-disc px-4 text-zinc-700 text-md'>
                        {Array.isArray(dataJobDetil?.requirement) && dataJobDetil?.requirement.map((value, key) => {
                            return <li key={key}>{value.requirement}</li>
                        })}
                        {/* <li>Bachelor’s degree from any major</li>
                        <li>Minimum 2 years of experience in leading a Customer Support team</li>
                        <li>Proficiency in operating Google Workspace (Google Docs, Google Sheets, etc) and Ms. Office</li>
                        <li>Structured thinking skills with the ability to evaluate current problems and come up with effective solutions</li>
                        <li>Proven leadership skills</li>
                        <li>Willing to be placed in Yogyakarta</li> */}
                    </ul>
                    {/* <div className='my-5 text-gray-800'>
                        <p>Bukalapak is an equal-opportunity employer. Our hiring committee considers all applicants based on their merits and qualifications for each position. Bukalapak does not discriminate applicants on the basis of their race, color, religion, sex, sexual orientation, gender identity, national origin, disability, or any other characteristics protected by applicable law. We embrace diversity and encourage inclusion because we believe that to help create A Fair Economy For All, we need to build a workplace for all #BukalapakForAll</p>
                    </div> */}
                    <div className='bg-gradient-to-t from-white to-transparent w-full sticky flex bottom-0 justify-center items-center mt-3 py-3'>
                        <button onClick={detectBackBtn} className='bg-gradient-to-r from-pink-700 to-pink-500 hover:from-pink-500 hover:to-pink-700 transition-colors duration-300 py-2 px-10 rounded-full text-white font-semi'>
                            Ajukan Lamaran
                        </button>
                    </div>
                </div>
                {!isOpenForm && <WAHelp />}
            </section>
            <FooterMain />
        </div>
    )
}

export default DetilJob;

// const dataLastEdu = [
//     { label: 'SD', type: 'edu', value: '1', mainDesc: null, desc: null },
//     { label: 'SMP', type: 'edu', value: '2', mainDesc: null, desc: null },
//     { label: 'SMA', type: 'edu', value: '3', mainDesc: null, desc: null },
//     { label: 'D3', type: 'edu', value: '4', mainDesc: null, desc: null },
//     { label: 'D4/S1', type: 'edu', value: '5', mainDesc: null, desc: null },
//     { label: 'S2', type: 'edu', value: '6', mainDesc: null, desc: null },
// ]

const dataKelengkapan = [
    { id: 0, nameTab: 'Data Personal' },
    { id: 1, nameTab: 'Pendidikan' },
    { id: 2, nameTab: 'Pengalaman Kerja' },
    { id: 3, nameTab: 'Keahlian' },
    { id: 4, nameTab: 'Social Media' },
    // { id: 4, nameTab: 'Bahasa' },
    // { id: 2, nameTab: 'Pendidikan Non Formal' },
    // { id: 6, nameTab: 'Hobi' },
    // { id: 8, nameTab: 'Hubungan Keluarga' },
    // { id: 7, nameTab: 'Riwayat Penyakit' },
]