import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

import Navbar from "../../components/navbar/navbar"
import { HeroHome, SearchBox } from "../../components/hero/hero";
import { BoxJobAvail, BoxProcedure, BoxProcedureDks } from "../../components/box/box";

import { HiFilter as IconFilter } from 'react-icons/hi';
import {
    // dbDept, dbJob, dbLoc, dbRank, departmentArr, levelArr,
    FilterDrawer, MainDrawer, MenuFilter, MenuFilterItem, MenuFilterItemDks, MenuFilterMobile
} from "../../components/drawer/drawer";
import { FooterMain } from "../../components/footer/footer";
import { getAllDataJob, getDataDept, getDataLoc, getDataPangkat } from "../../helper/api/dataJob";
import { OverlayLoading } from "../../components/loading";
import { HomePagination } from "../../components/paginate/paginate";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { LgLifeAtMutif } from "../../assets/img";
import { WAHelp } from "../../components/btn/btn";

const Home = () => {
    const [isOpenDrawer, setisOpenDrawer] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [isLoadAllJob, setIsLoadAllJob] = useState(false);
    const [isLoadPangkat, setIsLoadPangkat] = useState(false);
    const [isLoadDept, setIsLoadDept] = useState(false);
    const [isLoadLoc, setIsLoadLoc] = useState(false);

    const [infoPagination, setInfoPagination] = useState(null);
    const [page, setPage] = useState(1);

    const [filterDept, setFilterDept] = useState([]);
    const [filterPangkat, setFilterPangkat] = useState([]);
    const [filterLok, setFilterLok] = useState([]);

    const [data, setData] = useState([]);
    const [dataPangkat, setDataPangkat] = useState([]);
    const [dataDept, setDataDept] = useState([]);
    const [dataLoc, setDataLoc] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        firstRender();
    }, [])

    useEffect(() => {
        formatFilter();
    }, [filterDept, filterLok, filterPangkat, page]);

    function formatFilter() {
        let _caseDept = [];
        let _caseRank = [];
        let _caseLok = [];

        filterDept.map(value => {
            _caseDept.push(value.id);
        });

        filterPangkat.map(value => {
            _caseRank.push(value.id);
        })

        filterLok.map(value => {
            _caseLok.push(value.id);
        })

        filterData(_caseDept, _caseRank, _caseLok, page);

        // console.log(`Case Dept`, _caseDept);
    }

    function filterData(dept, rank, loc, page) {
        setIsLoadAllJob(true);
        getAllDataJob(dept, rank, loc, page)
            .then(res => {
                if (res.result) {
                    setData(res?.result?.data);
                    // console.log('data job', res);
                    setInfoPagination(res?.result);

                }
                setIsLoadAllJob(false);
            })
            .catch(err => {
                // console.log(`Error Data All Job`, err);
                setIsLoadAllJob(false);
                toast('Error get data Job', { type: 'error', autoClose: 2000 });
            })
    }

    function firstRender() {
        setIsLoadAllJob(true);

        setIsLoadPangkat(true);
        setIsLoadDept(true);
        setIsLoadLoc(true);

        getDataPangkat()
            .then(res => {
                // console.log('data pangkat', res.result);
                setIsLoadPangkat(false);
                if (res.result) {
                    setDataPangkat(res.result);
                }
            })
            .catch(error => {
                // console.log(`Error Data Pangkat`, error);
                setIsLoadPangkat(false);
                toast(`Error get data pangkat`, { type: 'error', autoClose: 2000 });

            })

        getDataDept()
            .then(res => {
                // console.log(res);
                // console.log('data dept', res.result);
                if (res.result) {
                    setDataDept(res.result);
                    setIsLoadDept(false);
                }
            })
            .catch(err => {
                // console.log(`Error Data Dept`, err);
                setIsLoadDept(false);
                toast('Error get data department', { type: 'error', autoClose: 3000 });
            })

        getDataLoc()
            .then(res => {
                // console.log(res);
                // console.log('data loc', res.result)
                if (res.result) {
                    setDataLoc(res.result);
                    setIsLoadLoc(false);
                }
            })
            .catch(err => {
                // console.log(`Error Data Loc`, err);
                setIsLoadLoc(false);
                toast('Error get data lokasi', { type: 'error', autoClose: 2000 });
            })
    }

    return (
        <div className="bg-zinc-100">
            <OverlayLoading isLoading={isLoadPangkat || isLoadDept || isLoadLoc || isLoadAllJob} />
            <ToastContainer />
            <Navbar onOpenMenu={() => setisOpenDrawer(!isOpenDrawer)} forcedChangeColor={isOpenFilter} />
            <HeroHome />
            <MainDrawer isOpen={isOpenDrawer} onClose={() => setisOpenDrawer(!isOpenDrawer)} />
            <div className={`fixed z-[999] w-full h-full pt-16 md:hidden ${isOpenFilter ? 'bottom-0 opacity-100' : '-bottom-full opacity-0'} ease-in transition-all duration-300`}>
                <FilterDrawer
                    isOpen={isOpenFilter}
                    onClose={() => setIsOpenFilter(!isOpenFilter)}
                    childDept={<MenuFilterItem totalItem={filterDept.length} title={'department'}
                        child={dataDept.map((val, key) => {
                            const foundCheck = filterDept.find(element => element.id === val.id);

                            return <MenuFilterMobile key={key} label={val.label} value={val.label} isChecked={foundCheck} onChangeValue={e => {
                                let caseFilter = [...filterDept];
                                let finalData;

                                const data = { id: val.id, label: val.label };
                                if (e.target.checked) {
                                    caseFilter.push(data);
                                    setFilterDept(caseFilter);
                                } else {
                                    finalData = caseFilter.filter(element => data.label !== element.label);
                                    setFilterDept(finalData);
                                }
                            }} />
                        })}
                    />}
                    childRank={<MenuFilterItem totalItem={filterPangkat.length} title={'pangkat'}
                        child={dataPangkat.map((val, key) => {
                            const foundCheck = filterPangkat.find(element => element.id === val.id);

                            return <MenuFilterMobile key={key} label={val.label} value={val.label} isChecked={foundCheck} onChangeValue={e => {
                                let caseFilter = [...filterPangkat];
                                let finalData;

                                const data = { id: val.id, label: val.label };
                                if (e.target.checked) {
                                    caseFilter.push(data);
                                    setFilterPangkat(caseFilter);
                                } else {
                                    finalData = caseFilter.filter(element => data.label !== element.label);
                                    setFilterPangkat(finalData);
                                }
                            }} />
                        })}
                    />}
                    childLoc={<MenuFilterItem totalItem={dataLoc.length} title={'lokasi'}
                        child={dataLoc.map((val, key) => {
                            const foundCheck = filterLok.find(element => element.id === val.id);

                            return <MenuFilterMobile key={key} label={val.label} value={val.label} isChecked={foundCheck} onChangeValue={e => {
                                let caseFilter = [...filterLok];
                                let finalData;

                                const data = { id: val.id, label: val.label };
                                if (e.target.checked) {
                                    caseFilter.push(data);
                                    setFilterLok(caseFilter);
                                } else {
                                    finalData = caseFilter.filter(element => data.label !== element.label);
                                    setFilterLok(finalData);
                                }
                            }} />
                        })}
                    />}
                />
            </div>
            <section className="flex flex-col items-center px-5 md:px-[7%] lg:px-[15%] mt-10">
                <div className="w-full mt-7 flex">
                    <div className={`max-md:hidden w-[30%]`}>
                        <p className="font-semibold md:font-bold text-2xl md:text-3xl lg:text-3xl mb-3 ml-3">Filter</p>
                        <MenuFilterItemDks totalItem={filterDept.length} showMore={filterDept.length > 0 ? true : false} title={'department'} child={dataDept.map((val, key) => {
                            const foundCheck = filterDept.find(element => element.id === val.id);

                            return <MenuFilter key={key} label={val.label} value={val.ID} isChecked={foundCheck} onChangeValue={e => {
                                let caseFilter = [...filterDept];
                                let finalData;

                                const data = { id: val.ID, label: val.label };
                                if (e.target.checked) {
                                    caseFilter.push(data);
                                    setFilterDept(caseFilter);
                                } else {
                                    finalData = caseFilter.filter(element => data.label !== element.label);
                                    setFilterDept(finalData);
                                }
                            }} />
                        })} />
                        <MenuFilterItemDks totalItem={filterPangkat.length} showMore={filterPangkat.length > 0 ? true : false} title={'pangkat'} child={dataPangkat.map((val, key) => {
                            const foundCheck = filterPangkat.find(element => element.id === val.id);

                            return <MenuFilter key={key} label={val.label} value={val.ID} isChecked={foundCheck} onChangeValue={e => {
                                let caseFilter = [...filterPangkat];
                                let finalData;

                                const data = { id: val.ID, label: val.label };
                                if (e.target.checked) {
                                    caseFilter.push(data);
                                    setFilterPangkat(caseFilter);
                                } else {
                                    finalData = caseFilter.filter(element => data.label !== element.label);
                                    setFilterPangkat(finalData);
                                }
                            }} />
                        })} />
                        <MenuFilterItemDks totalItem={filterLok.length} showMore={filterLok.length > 0 ? true : false} title={'lokasi'} child={dataLoc.map((val, key) => {
                            const foundCheck = filterLok.find(element => element.id === val.id);

                            return <MenuFilter key={key} label={val.label} value={val.ID} isChecked={foundCheck} onChangeValue={e => {
                                let caseFilter = [...filterLok];
                                let finalData;

                                const data = { id: val.id, label: val.label };
                                if (e.target.checked) {
                                    caseFilter.push(data);
                                    setFilterLok(caseFilter);
                                } else {
                                    finalData = caseFilter.filter(element => data.label !== element.label);
                                    setFilterLok(finalData);
                                }
                            }} />
                        })} />
                    </div>
                    <div className="w-full md:w-[70%] relative">
                        <div className="flex w-full justify-between mb-3">
                            <p className="font-semibold md:font-bold text-2xl md:text-3xl lg:text-3xl">Posisi Tersedia</p>
                            <span className="font-bold text-slate-500 text-xl md:text-2xl">{data.length} Posisi</span>
                        </div>
                        {data.length > 0 ? data.map((value, key) => {
                            return <BoxJobAvail key={key} nameJob={value.posisi} division={value?.divisi || "KOSONG"} level={value?.position_title || "KOSONG"} location={value?.lokasi || "KOSONG"} onPress={() => {
                                navigate(`/detil/${value.id}`, { preventScrollReset: true })
                            }} />
                        }) : (
                            <div className="flex items-center justify-center py-20 bg-white rounded-lg shadow mb-3">
                                <p className="text-3xl font-semibold text-center text-gray-500 uppercase">LOKER Kosong</p>
                            </div>
                        )}
                        <HomePagination pageNow={page} from={infoPagination?.from} to={infoPagination?.to} total={infoPagination?.total} onNext={() => {
                            if (page === infoPagination?.last_page) {
                                setPage(infoPagination?.last_page);
                            } else {
                                setPage(page + 1);
                            }
                        }} onPrevious={() => {
                            if (page === 1) {
                                setPage(1)
                            } else {
                                setPage(page - 1);
                            }
                        }} />
                    </div>
                </div>
                <button onClick={() => setIsOpenFilter(!isOpenFilter)} className='fixed bottom-5 right-3 p-3 bg-white shadow-md rounded-3xl text-pink-600 active:opacity-30 z-30 md:hidden'>
                    <IconFilter className="text-2xl" />
                </button>
                <div className="box-content w-full md:mb-10 py-10 border-y border-slate-400 mt-11 mb-20 ">
                    <h3 className="text text-center mb-10 text-2xl font-semibold font-body">Prosedur Rekrutmen</h3>
                    <div className={`md:hidden`}>
                        <BoxProcedure noStep={'1'} />
                        <BoxProcedure noStep={'2'} />
                        <BoxProcedure noStep={'3'} />
                        <BoxProcedure noStep={'4'} />
                        <BoxProcedure noStep={'5'} />
                    </div>
                    <div className="grid grid-cols-7 gap-3 max-md:hidden">
                        {procedureApplicant.map((value) => {
                            return <BoxProcedureDks noStep={value.no} label={value.label} desc={value.desc} />
                        })}
                    </div>
                </div>
                <WAHelp />
            </section>
            <section>
                <FooterMain />
            </section>
        </div>
    )
}

export default Home;

const procedureApplicant = [
    {
        no: 1,
        label: "Adm Selection",
        desc: ""
    },
    {
        no: 2,
        label: "Culture Fit",
        desc: ""
    },
    {
        no: 3,
        label: "Psikotes",
        desc: ""
    },
    {
        no: 4,
        label: "User Test & Interview",
        desc: ""
    },
    {
        no: 5,
        label: "Salary Negotiation",
        desc: ""
    },
    {
        no: 6,
        label: "On Boarding",
        desc: ""
    },
    {
        no: 7,
        label: "Close Position",
        desc: ""
    },
]