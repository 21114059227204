import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { IoMdClose as IconClose } from 'react-icons/io';
import { HiOutlineChevronDown as IconDropDown, HiOutlineChevronUp as IconDropUp } from 'react-icons/hi';
import { useState } from 'react';
import { Collapse } from 'react-collapse';

export const MainDrawer = ({ isOpen, onClose }) => {
    return (
        <Drawer open={isOpen} onClose={onClose} direction='right' className='md:hidden transition-all ease-in w-full' zIndex={9999} >
            <div className='p-3'>
                <button className='flex justify-end w-full' onClick={onClose}>
                    <IconClose className='text-3xl' />
                </button>
                <div role={'menubar'} className={'flex flex-col mt-2'}>
                    <a role={'menuitem'} href={'/'} className={'mt-4 text-lg font-semibold text-slate-800 hover:text-pink-700 transition-colors active:text-pink-600 '}>Home</a>
                    <a role={'menuitem'} href={'/'} className={'mt-4 text-lg font-semibold text-slate-800 hover:text-pink-700 transition-colors active:text-pink-600 '}>Job</a>
                    <a role={'menuitem'} href={'/'} className={'mt-4 text-lg font-semibold text-slate-800 hover:text-pink-700 transition-colors active:text-pink-600 '}>About</a>
                    <a role={'menuitem'} href={'/'} className={'mt-4 text-lg font-semibold text-slate-800 hover:text-pink-700 transition-colors active:text-pink-600 '}>Life@Mutif</a>
                </div>
            </div>
        </Drawer>
    )
}

export const DrawerNavFormRegist = ({ isOpen, onClose, children }) => {
    return (
        <Drawer open={isOpen} onClose={onClose} direction='right' className='md:hidden transition-all ease-in w-full'>
            {children}
        </Drawer>
    )
}

export const FilterDrawer = ({ onClose, childDept, childRank, childLoc }) => {
    return (
        <div className='p-3 bg-white w-full h-full overflow-y-scroll overflow-x-hidden'>
            <div className={`overflow-scroll`}>
                <button className='flex justify-between w-full border-b pt-2 pb-5' onClick={onClose}>
                    <span className={`text-2xl font-semibold font-body`}>Filter Pekerjaan</span>
                    <IconClose className='text-3xl' />
                </button>
                <div role={'menu'} className={'flex flex-col'}>
                    {childDept}
                    {childRank}
                    {childLoc}
                    {/* <MenuFilterItem title={'Department'} data={departmentArr} /> */}
                    {/* <MenuFilterItem title={'Level'} data={levelArr} /> */}
                    {/* <MenuFilterItem title={'Location'} /> */}
                </div>
            </div>
        </div>
    )
}

export const MenuFilterItem = ({ title, child, totalItem }) => {
    const [isShowMore, setIsShowMore] = useState(true);

    return (
        <div role={'menu'} className={'flex flex-col'}>
            <div className={`border-b p-4`}>
                <div className='flex justify-between mb-5'>
                    <h3 className={`font-semibold text-xl uppercase text-slate-900`}>{title}</h3>
                    <button className='text-2xl' onClick={() => setIsShowMore(!isShowMore)}>
                        {isShowMore ? <IconDropUp /> : <IconDropDown />}
                    </button>
                </div>
                {/* <ExampleCollapse /> */}
                {/* {(Array.isArray(data) && Array.isArray(dataFilter)) && (
                    <form onChange={onChangeValue} className={`mt-4 ${isShowMore ? 'block' : 'hidden'} transition-all`}>
                        {data.map((val, key) => {
                            return <MenuFilter key={key} label={val.label} value={val.label} />
                        })}
                    </form>
                )} */}
                <Collapse isOpened={isShowMore}>
                    <div className={`w-full overflow-y-auto ${totalItem < 7 ? 'h-auto' : 'h-96'} pl-3`}>
                        {child}
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

const ExampleCollapse = () => {
    const [isOpened, setIsOpened] = useState(false);

    return (
        <div>
            <div className="config">
                <label className="label">
                    Opened:
                    <input
                        className="input"
                        type="checkbox"
                        checked={isOpened}
                        onChange={({ target: { checked } }) => setIsOpened(checked)} />
                </label>
            </div>

            <Collapse isOpened={isOpened}>
                <div className="text">
                    <p>Mantap jiwa bos</p>
                    {/* {paragraph ? <p>Mantap Jiwa Jos</p> : <p>No text</p>} */}
                </div>
            </Collapse>
        </div>
    );
}

export const MenuFilterMobile = ({ label, value, isChecked, onChangeValue }) => {
    return (
        <div className="flex items-center mb-4 text-lg">
            <input type="checkbox" value={value} onChange={onChangeValue} checked={isChecked} className="w-4 h-4 text-pink-600 bg-gray-100 rounded border-gray-200 accent-pink-600 ml-2" />
            <label className="ml-2 font-medium text-gray-900">{label}</label>
        </div>
    )
}

export const MenuFilterItemDks = ({ title, child, totalItem, showMore = false }) => {
    const [isShowMore, setIsShowMore] = useState(true);

    return (
        <div role={'menu'} className={'flex flex-col mb-3'}>
            <div className={`px-4 rounded-lg ReactCollapse--collapse`}>
                <div className={`flex justify-between bg-gradient-to-r from-pink-700 to-pink-500 p-3 text-white shadow ${isShowMore ? 'rounded-t-lg' : 'rounded-lg'}`}>
                    <h3 className={`font-semibold uppercase lg:text-md`}>{title}</h3>
                    <button className='text-2xl' onClick={() => setIsShowMore(!isShowMore)}>
                        {isShowMore ? <IconDropUp /> : <IconDropDown />}
                    </button>
                </div>
                <Collapse isOpened={isShowMore} className={`ReactCollapse--content`}>
                    <div className={`w-full overflow-y-auto bg-white pt-4 ${totalItem < 7 ? 'h-auto' : 'h-96'} pl-3 p-4 rounded-b-lg shadow transition-all`}>
                        {child}
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export const MenuFilter = ({ label, value, isChecked, onChangeValue }) => {
    return (
        <div className="flex items-center mb-4 text-md w-full">
            <input type="checkbox" value={value} onChange={onChangeValue} checked={isChecked} className="w-4 h-4 text-pink-600 bg-gray-100 rounded border-gray-200 accent-pink-600" />
            <label className="ml-2 font-medium text-gray-900">{label}</label>
        </div>
    )
}

export const departmentArr = [
    { label: 'IT', value: 'IT' },
    { label: 'PPIC', value: 'PPIC' },
    { label: 'HRDGA', value: 'HRDGA' },
    { label: 'HCDGA', value: 'HCDGAT' },
    { label: 'SALES', value: 'SALES' },
    { label: 'MARKETING', value: 'MARKETING' },
    { label: 'VM', value: 'VM' },
]

export const levelArr = [
    { label: 'INTERNSHIP', value: 'INTERNSHIP' },
    { label: 'DAILY WORKER', value: 'DAILY WORKER' },
    { label: 'MANAGER', value: 'MANAGER' },
    { label: 'LEADER', value: 'LEADER' },
    { label: 'STAFF', value: 'STAFF' },
]

export const dbJob = [
    {
        "pgj_code": "PGJ-2211991005",
        "pgj_pangkat_id": 13,
        "pgj_posisi": "Cyber Security",
        "pgj_dept_id": 994,
        "pgj_lokasi": "Bekasi SDI",
        "departement": "Sales Marketing Dept. Head",
        "pangkat": "Manager"
    },
    {
        "pgj_code": "PGJ-2211991004",
        "pgj_pangkat_id": 13,
        "pgj_posisi": "Mobile Developer",
        "pgj_dept_id": 993,
        "pgj_lokasi": "Bandung SDI",
        "departement": "Finance Accounting Dept. Head",
        "pangkat": "Manager"
    },
    {
        "pgj_code": "PGJ-2211223003",
        "pgj_pangkat_id": 13,
        "pgj_posisi": "Frontend Developer",
        "pgj_dept_id": 992,
        "pgj_lokasi": "Bandung SDI",
        "departement": "IT Dept. Head",
        "pangkat": "Manager"
    },
    {
        "pgj_code": "PGJ-2211002",
        "pgj_pangkat_id": 13,
        "pgj_posisi": "UI/UX Designer",
        "pgj_dept_id": 992,
        "pgj_lokasi": "Bandung SDI",
        "departement": "IT Dept. Head",
        "pangkat": "Manager"
    },
    {
        "pgj_code": "PGJ-2211991001",
        "pgj_pangkat_id": 5,
        "pgj_posisi": "Backend Developer",
        "pgj_dept_id": 991,
        "pgj_lokasi": "Jakarta SDI",
        "departement": "Operational Departement Head",
        "pangkat": "Junior Staff"
    }
];

export const dbLoc = [
    {
        "id": 991040,
        "label": "Bandung SDI"
    },
    {
        "id": 991043,
        "label": "Jakarta Kejaksaan"
    },
    {
        "id": 991049,
        "label": "Yogyakarta SDI"
    },
    {
        "id": 991058,
        "label": "Medan Publishing"
    },
    {
        "id": 991047,
        "label": "Yogyakarta"
    },
    {
        "id": 991048,
        "label": "Jakarta SDI Pusat"
    },
    {
        "id": 106,
        "label": "Makasar Publishing"
    },
    {
        "id": 991042,
        "label": "Bandung Kalijati"
    },
    {
        "id": 991041,
        "label": "Bandung Pusat"
    },
    {
        "id": 991044,
        "label": "Jakarta SDI"
    },
    {
        "id": 991046,
        "label": "Surabaya SDI"
    },
    {
        "id": 991045,
        "label": "Surabaya Publishing"
    }
];

export const dbRank = [
    {
        "id": "13",
        "label": "Manager"
    },
    {
        "id": "12",
        "label": "Assistant Manager 2"
    },
    {
        "id": "11",
        "label": "Assistant Manager 1"
    },
    {
        "id": "10",
        "label": "Supervisor"
    },
    {
        "id": "8",
        "label": "Staff 3"
    },
    {
        "id": "7",
        "label": "Staff 2"
    },
    {
        "id": "6",
        "label": "Staff 1"
    },
    {
        "id": "16",
        "label": "Vice President Director"
    },
    {
        "id": "17",
        "label": "Executive Vice President Director"
    },
    {
        "id": "15",
        "label": "General Manager"
    },
    {
        "id": "5",
        "label": "Junior Staff"
    },
    {
        "id": "9",
        "label": "Senior Staff"
    },
    {
        "id": "14",
        "label": "Senior Manager"
    },
    {
        "id": "18",
        "label": "President Director"
    }
];

export const dbDept = [
    {
        "id": 991,
        "label": "Operational Departement Head"
    },
    {
        "id": 992,
        "label": "IT Dept. Head"
    },
    {
        "id": 993,
        "label": "Finance Accounting Dept. Head"
    },
    {
        "id": 994,
        "label": "Sales Marketing Dept. Head"
    }
];