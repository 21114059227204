import moment from 'moment/moment';
import { FaInstagram, FaMapPin as IconPinMap, FaPhoneAlt as IconPhone, FaYoutube } from 'react-icons/fa';
import { RiCopyrightLine as IconCopyRight } from 'react-icons/ri'
import { IcDomain } from '../../assets/img';

export const FooterMain = () => {
    function linking(e, type, data) {
        e.preventDefault();
        switch (type) {
            case 'mailto':
                window.location.href = `mailto:${data}`;
                break;
            case 'tel':
                window.location.href = `tel:${data}`;
                break;
            case 'whatsapp':
                let number = "";
                if (data[0] === 0) {
                    data.slice(0, (data.length));
                    number = `62${data}`;
                } else {
                    number = data;
                }
                window.location.href = `https://wa.me/${number}`;
                break;
            case 'instagram':
                window.location.href = `https://www.instagram.com/${data}`
                break;
            case 'newTab':
                window.open(data.toString(), '_blank')
                break;
            default:
                break;
        }
    }


    return (
        <div>
            <div className='bg-slate-800 p-5 px-15 md:px-[15%] box-content'>
                <div className={`flex w-full justify-between items-center`}>
                    <div className='w-1/4 md:w-[10%]'>
                        <img src={require('../../assets/img/MutifCorpWhite.png')} alt={'logo Mutif Career'} className={'w-full'} />
                    </div>
                    <div>
                        <p className={`text-white text-sm`}>IKUTI KAMI</p>
                        <div className={`flex gap-3 mt-2 text-2xl text-white`}>
                            <div className='cursor-pointer' onClick={(e) => linking(e, 'newTab', 'https://mutif.id/')}>
                                <img src={IcDomain} className='h-6' alt='Icon Domain' />
                            </div>
                            <div className='cursor-pointer' onClick={(e) => linking(e, 'newTab', 'https://www.instagram.com/mutifcorp/')}>
                                <FaInstagram />
                            </div>
                            <div className='cursor-pointer' onClick={e => linking(e, 'newTab', 'https://www.youtube.com/channel/UCcNyltfFTlg64puWkeaB14Q')}>
                                <FaYoutube />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-white mt-5 flex flex-col gap-2'>
                    <div className={`flex box-content gap-2 text-sm`}>
                        <IconPinMap />
                        <p>Jl. Raya Padalarang No.783, Padalarang, Kec. Padalarang, Kabupaten Bandung Barat, Jawa Barat 40553</p>
                    </div>
                    <div className={`flex box-content gap-2 text-sm items-center`}>
                        <IconPhone />
                        <p>(022) 8680060</p>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center bg-neutral-900 text-white py-1 text-md'>
                <IconCopyRight />
                <span className='mx-1'>{moment().format("YYYY")}</span>
                <p className='font-semibold uppercase'>copyright mutif corp</p>
            </div>
        </div>
    )
}