import { create } from 'zustand';

const useStoreForm = create((set) => ({
  personal: {
    data: {},
    isEdit: true,
    isDone: false,
    label: "Form Personal" 
  },
  eduFormal: {
    data: [],
    isEdit: true,
    isDone: false,
    label: "Pendidikan Formal" 
  },
  eduNonFormal: {
    data: [],
    isEdit: true,
    isDone: false,
    label: "Pendidikan NonFormal" 
  },
  histJob: {
    data: [],
    isEdit: true,
    isDone: false,
    label: "Riwayat Pekerjaan" 
  },
  skillLang: {
    data: [],
    isEdit: true,
    isDone: false,
    label: "Bahasa Pelamar" 
  },
  skillApplicant: {
    data: [],
    isEdit: true,
    isDone: false,
    label: "Keahlian Pelamar" 
  },
  hobby: {
    data: [],
    isEdit: true,
    isDone: false,
    label: "Hobi Pelamar" 
  },
  socmed: {
    data: [],
    isEdit: true,
    isDone: false,
    label: "Sosial Media" 
  },
  question: {
    data: [],
    isEdit: true,
    isDone: false,
    label: "Pertanyaan",
    code: ""
  },
  dataProvince: [],
  dataCity: [],
  dataRegency: [],
  dataGender: [],
  dataFamilyEmergency: [],
  dataLevel: [],
  dataStatusMarital: [],
  dataGolDarah: [],
  dataSim: [],
  dataSosmed: [],
  dataStatusHome: [],
  dataPosition: [],
  dataJenisBisnis: [],
  dataEduNonFormal: [],
  dataJurusan: [],
  dataGrade: [],
})
)

export default useStoreForm;