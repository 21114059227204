// import { useEffect } from "react";
import { forwardRef } from "react"
import { ErrorComponent } from "../errorComponent"
import { IoMdSave as IconSave } from 'react-icons/io';
import { MdDateRange as IconDate, MdAdd as IconAdd, MdEdit as IconEdit } from 'react-icons/md';
import { toast } from "react-toastify";
import Modal from 'react-modal';
import { FiAlertCircle as IconAlert } from 'react-icons/fi';
import Select from 'react-select';

export {IconSave, IconAdd, IconEdit, IconDate, IconAlert, Modal}

export const TitleForm = ({ title }) => {
    return (
        <div className="flex items-center justify-center mb-4">
            <h1 className="text-2xl font-semibold">{title}</h1>
        </div>
    )
}

export const Form = ({ children, isOpenSideBar, ...props }) => {
    return (
        <form className={`w-full md:px-3 ${isOpenSideBar && ('hidden')} md:block`} {...props}>
            {children}
        </form>
    )
}

export const InputForm = forwardRef((props, ref) => {
    return (
        <div className="mt-1.5">
            <div className={`mb-2 ${props.iscenterLabel && 'text-center text-sm lg:text-xl'}`}>
                <label htmlFor={props.label} className={`${props.isrequired && "after:content-['*'] after:ml-0.5 after:text-red-500"} font-medium`}>{props.label}</label>
                {props.msgcondition && <p className={'text-sm text-gray-600'}>{props.msgcondition}</p>}
            </div>
            {props.istextarea ? (
                <textarea rows={'4'} {...props} className="px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-pink-500 focus:ring-pink-500 block w-full rounded-md sm:text-sm focus:ring-1" />
            ) : props.isdropdown ? (
                <Select {...props} styles={{ menu: (base) => ({ ...base, position: props.positionSelect || "absolute" }) }} />
                // <select id={props.label} className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5" {...props}>
                //     <option>-- Pilih {props.label} --</option>
                //     {Array.isArray(props.dataOpt) && props.dataOpt.map((val, key) => <option key={key} value={val.value}>{val.label}</option>)}
                // </select>
            ) : props.isTypeFIle ? (
                <div>
                    <div className="flex gap-2">
                        <input id={props.label} ref={ref} {...props} className={`w-full px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-pink-500 focus:ring-pink-500 block rounded-md text-xs md:text-md focus:ring-1`} aria-describedby="file_input_help" type="file" />
                        {/* {props.value ? (
                            <button onClick={props.pressView} className='bg-green-500 flex gap-2 items-center justify-center text-white py-1 px-3 rounded w-1/2'>
                                <IconView className="text-white" />
                                <span>View</span>
                            </button>
                        ) : null} */}
                    </div>
                    {props.msgConditionFile && (<p className="mt-1 text-sm text-gray-500">{props.msgConditionFile}.</p>)}
                </div>
            ) : props.isPhoneNumber ? (
                <div className='flex items-center box-content'>
                    <p className='mr-3 p-2 border rounded-md border-gray-300'>+62</p>
                    <input {...props} type={"tel"} className="mt-1 px-3 py-2.5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-pink-500 focus:ring-pink-500 block w-full rounded-md text-sm focus:ring-1" />
                </div>
            ) : (
                <input id={props.label} {...props} ref={ref} className="px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-pink-500 focus:ring-pink-500 block w-full rounded-md text-xs md:text-md focus:ring-1" />
            )}
            {/* {props.errors[props.controlname] && (
                <div className="flex text-red-500 text-base items-center gap-1 mt-1">
                    <IconAlert />
                    <p>{props.errors[props.controlname]?.message}</p>
                </div>
            )} */}
            <ErrorComponent errorObj={props.errors} labelError={props.controlname} />
        </div>
    )
})

export const ResultItem = ({ label, children, isChildren, value }) => {
    return (
        <div className={`${isChildren ? "flex flex-col" : "grid grid-flow-col grid-cols-3 border-b border-slate-400"} py-4`}>
            <label className='font-medium col-span-1'>{label}</label>
            {isChildren ? children : (
                <span className='col-span-2'>: {value}</span>
            )}
        </div>
    )
}

export const onHandleFile = (e, label) => {
    const files = e?.target?.files[0];
    if(files.size <= 3000000) {
        return e?.target?.files;
    } else {
        toast.warn(`${label} lebih dari 3 mb`);
        return null;
    }
}

export const BoxArrResult = ({ label, value }) => {
    return (
        <div className="grid grid-flow-col grid-cols-3 gap-2 border-b border-slate-300 pb-2 text-slate-600">
            <label className="col-span-1">{label}</label>
            <p className="col-span-2 truncate">: {value}</p>
        </div>
    )
}

export const ruleRequired = (msg) => {
    return { value: true, message: msg }
}

export const EditScreen = ({ isHasItem, isOpenSideBar, onPressEdit, titleForm }) => {
    return (
        <div className={`md:px-3 ${isOpenSideBar && ('hidden')} md:block gap-1 md:${isHasItem ? "w-1/2" : "w-[100%]"} relative`}>
            <TitleForm title={titleForm} />
            <div className="text-slate-400 border border-slate-300 p-2 flex flex-col items-center justify-center gap-2">
                <p>Tekan tombol edit untuk edit form</p>
                <button onClick={onPressEdit} className={'border rounded border-slate-400 px-5 py-1 flex hover:bg-slate-500 hover:text-white active:opacity-50 items-center gap-2'}>
                    <span><IconEdit /></span>
                    <span>Edit</span>
                </button>
            </div>
        </div>
    )
}

export const ModalForm = ({ isOpen, onRequestClose, msgAlert, onPressFalse, onPressTrue, typeAlert }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="bg-white p-3 rounded-lg shadow">
                <div className="flex flex-col items-center justify-center gap-3">
                    <IconAlert className={`${typeAlert === 1 ? "text-yellow-500" : "text-red-500"} text-5xl`} />
                    <p>{msgAlert}</p>
                </div>
                <div className="flex gap-3 text-white justify-center items-center mt-3">
                    <button onClick={onPressFalse} className="bg-red-500 hover:bg-red-400 active:bg-red-300 px-3 py-1 rounded">Tidak</button>
                    <button onClick={onPressTrue} className="bg-green-500 hover:bg-green-400 active:bg-green-300 px-3 py-1 rounded">Ya</button>
                </div>
            </div>
        </Modal>
    )
}

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.6)'
    }
};

export const dataBool = [
    { label: "Ya", value: true },
    { label: "Tidak", value: false },
]