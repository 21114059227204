import { useState, useEffect } from "react";
import useStoreForm from "../../../../store/state";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Transition } from "@headlessui/react";
import { BoxArrResult, EditScreen, Form, IconAdd, IconDate, IconSave, InputForm, ModalForm, Modal, TitleForm, ruleRequired, onHandleFile, dataBool } from "../components";
import { RegistSkils } from "./registSkils";

const formSkillLang = [{
    bahasa: "",
    lisan: "",
    tulisan: "",
}]

export const RegistLanguageApplicant = ({ isOpenSideBar }) => {
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState(1); // 0 alert delete, 1 alert simpan, 2 edit
    const [isEditMode, setIsEditMode] = useState(true);
    const [msgAlert, setMsgAlert] = useState("Apakah anda sudah yakin dengan data ini?");
    const [isRegistSkills, setIsRegisSkills] = useState(false);

    const storeLang = useStoreForm(state => state?.skillLang);
    const storeSkillAplicant = useStoreForm.getState()?.skillApplicant?.data;

    const { formState: { errors, isValid }, control, handleSubmit } = useForm({
        defaultValues: {
            skillLang: formSkillLang
        },
    });

    const { fields, remove, append, prepend } = useFieldArray({
        control: control,
        name: 'skillLang'
    })

    const watchSkillLang = useWatch({
        control: control,
        name: 'skillLang'
    })

    useEffect(() => {
        firstRender();
    }, []);

    function firstRender() {
        const _data = storeLang?.data;
        const _isEdit = storeLang?.isEdit;

        if (storeSkillAplicant.length > 0) {
            setIsRegisSkills(true);
        } else {
            setIsRegisSkills(false);
        }

        Modal.setAppElement('body');
        if (Array.isArray(_data)) {
            if (_data.length > 0) {
                prepend(_data);
            }
        }

        setIsEditMode(_isEdit);
    }

    async function onHandleSubmit(data) {
        if (isValid) {
            append(formSkillLang);
        } else {
            console.log("Error Handle")
        }
    }

    function removeItem(e, index) {
        e.preventDefault();
        remove(index);
    }

    function saveToStore(mode) {
        const filterValue = watchSkillLang?.filter(value => value.bahasa !== "");

        if (mode == 1) {
            useStoreForm.setState({ skillLang: { data: filterValue, isEdit: false, isDone: true } });
        } else if (mode == 2) {
            useStoreForm.setState({ skillLang: { data: filterValue, isEdit: true, isDone: false } });
        }
    }

    function saveForm() {
        const filterValue = fields.filter(value => value.bahasa !== "");
        useStoreForm.setState({ skillLang: { data: filterValue, isEdit: isEditMode, isDone: true } });
        setIsEditMode(false);
        setIsOpenAlert(false);
    }

    const routePresAlert = () => {
        switch (typeAlert) {
            case 1:
                saveToStore(1);
                break;
            case 2:
                setIsEditMode(true);
                setIsOpenAlert(false);
                break;
            case 3:
                useStoreForm.setState({ skillLang: { isDone: true, isEdit: false, data: [] } });
                setIsOpenAlert(false);
                setIsRegisSkills(false);
                break;
            default:
                setIsOpenAlert(false);
                break;
        }
    }

    return (
        <>
            <Transition
                show={true}
                appear={true}
                enter="transition-all duration-700"
                enterFrom="transform -translate-x-full opacity-0"
                enterTo="transform translate-x-0 opacity-100"
                leave="transition-all duration-500"
                leaveFrom="transform translate-x-0 opacity-100"
                leaveTo="transform translate-x-full opacity-0"
                className="mx-auto inset-0 z-20 overflow-y-scroll flex flex-col relative"
            >
                <ModalForm
                    isOpen={isOpenAlert}
                    typeAlert={typeAlert}
                    msgAlert={msgAlert}
                    onPressFalse={() => setIsOpenAlert(false)}
                    onPressTrue={routePresAlert}
                />
                <TitleForm title={'Bahasa Pelamar'} />
                <div className="flex divide-x-2 divide-slate-200 gap-3">
                    {isEditMode ? (
                        <Form className={`md:px-3 ${isOpenSideBar && ('hidden')} md:block gap-1 ${fields.length > 1 ? "w-1/2" : "w-[100%]"}`} onSubmit={handleSubmit(onHandleSubmit)}>
                            <div className="mt-4 flex items-center justify-end self-end gap-1.5">
                                <button onClick={() => {
                                    if (isValid) {
                                        append(formSkillLang);
                                        saveToStore(2);
                                    }
                                }} className="uppercase bg-green-500 hover:bg-green-400 active:bg-green-300 transition-colors text-white text-xs font-semibold rounded px-3 py-1.5 flex">
                                    <IconAdd />
                                    tambah
                                </button>
                            </div>
                            <Controller
                                rules={{ required: ruleRequired("Nama Bahasa masih kosong") }}
                                control={control} name={`skillLang[${fields.length - 1}].bahasa`}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputForm label={"Nama Bahasa"} type={'text'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`skillLang[${fields.length - 1}].bahasa`} />
                                )}
                            />
                            <div className='w-full grid grid-flow-col gap-3'>
                                <Controller
                                    rules={{ required: ruleRequired("Lisan masih kosong") }}
                                    control={control} name={`skillLang[${fields.length - 1}].lisan`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <InputForm label={"Lisan"} type={'number'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`skillLang[${fields.length - 1}].lisan`} />
                                    )}
                                />
                                <Controller
                                    rules={{ required: ruleRequired("Tulisan masih kosong") }}
                                    control={control} name={`skillLang[${fields.length - 1}].tulisan`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <InputForm label={"Tulisan"} type={'number'} isrequired={true} onChange={onChange} onBlur={onBlur} value={value} ref={ref} errors={errors} controlname={`skillLang[${fields.length - 1}].tulisan`} />
                                    )}
                                />
                            </div>
                            <div className="flex justify-center mt-2 gap-2">
                                <button onClick={() => {
                                    if (isValid) {
                                        saveToStore(1);
                                    }
                                }} className="uppercase cursor-pointer bg-pink-500 hover:bg-pink-400 active:bg-pink-300 transition-colors text-white text-xs font-semibold flex gap-1.5 rounded items-center px-3 py-1.5 mt-4">
                                    <IconSave />
                                    simpan
                                </button>
                            </div>
                        </Form>
                    ) : (
                        <EditScreen titleForm={"Bahasa"} isHasItem={fields.length > 1} isOpenSideBar={isOpenSideBar} onPressEdit={() => setIsEditMode(true)} />
                    )}
                    <ul className={`${fields.length > 1 ? "w-1/2" : "hidden"} pl-6 overflow-y-auto h-full`}>
                        {(Array.isArray(fields) && fields.length > 1) && (
                            fields.map((item, index) => {
                                const isLastItem = index === (fields.length - 1)
                                return (
                                    <Transition
                                        key={index}
                                        show={true}
                                        appear={true}
                                        enter="transition-all duration-700"
                                        enterFrom="transform -translate-x-full opacity-0"
                                        enterTo="transform translate-x-0 opacity-100"
                                        leave="transition-all duration-500"
                                        leaveFrom="transform translate-x-0 opacity-100"
                                        leaveTo="transform translate-x-full opacity-0"
                                        className={`${isLastItem && "hidden"} flex gap-3 rounded-l-md bg-white rounded-lg shadow-md mb-6`}
                                    >
                                        <div className={`bg-pink-400 w-[5%] rounded-l-lg text-white text-lg flex flex-col items-center justify-evenly`}>
                                            <h3 className="py-1 px-3 rounded-bl-lg text-lg">{index + 1}</h3>
                                        </div>
                                        <p className="text-xl font-bold">{item.nameInstitute}</p>
                                        <div className="grid grid-flow-col grid-rows-4 gap-2 w-[90%] items-center pt-4 relative">
                                            <div className="absolute top-0 right-0 flex gap-3">
                                                <button onClick={(e) => {
                                                    if (isEditMode) {
                                                        removeItem(e, index);
                                                    } else {
                                                        setIsOpenAlert(true);
                                                        setTypeAlert(2);
                                                        setMsgAlert("Anda harus mengaktifkan mode edit")
                                                    }
                                                }} className="text-pink-500 p-2 rounded hover:text-pink-300 active:text-pink-200 text-lg">X</button>
                                            </div>
                                            <BoxArrResult label={"Bahasa"} value={item.bahasa} />
                                            <BoxArrResult label={"Lisan"} value={item.lisan} />
                                            <BoxArrResult label={"Tulis"} value={item.tulisan} />
                                        </div>
                                    </Transition>
                                )
                            })
                        )}
                    </ul>
                </div>
                <button onClick={() => {
                    if (storeSkillAplicant?.length > 0 && isRegistSkills) {
                        setIsOpenAlert(true);
                        setMsgAlert("Anda yakin ingin menghapus data Keahlian lainnya?")
                        setTypeAlert(3);
                    } else {
                        setIsRegisSkills(!isRegistSkills);
                    }
                }} className={`uppercase cursor-pointer ${isRegistSkills ? "bg-red-500 hover:bg-red-400 active:bg-red-300" : "bg-blue-500 hover:bg-blue-400 active:bg-blue-300"} transition-colors text-white text-xs font-semibold gap-1.5 rounded items-center px-3 py-1.5 my-5 flex self-end justify-center w-52`}>
                    {isRegistSkills ? "Batal" : "Tambah Keahlian lainnya"}
                </button>
            </Transition>
            {isRegistSkills && (
                <div className="mt-5 pt-5 border-t border-slate-400">
                    <RegistSkils />
                </div>
            )}
        </>
    )
}