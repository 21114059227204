import { useState } from 'react';
import { HiOfficeBuilding as IconDivision } from 'react-icons/hi';
import { RiStackFill as IconLevel, RiMapPin2Fill as IconMapPin } from 'react-icons/ri';

export const BoxJobAvail = ({ nameJob, division, level, location, onPress }) => {
    return (
        <div className="w-full p-5 bg-white rounded-lg shadow hover:shadow-lg mb-2 cursor-pointer active:opacity-50 " onClick={onPress}>
            <p className="text-lg md:text-2xl font-semibold text-slate-900">{nameJob}</p>
            <div className="grid grid-flow-col grid-cols-3 gap-3 box-content divide-x-2 mt-3 md:mt-10 text-md text-slate-700">
                <div className="flex items-center">
                    <IconDivision className="mr-1 hidden lg:block" />
                    <p className='ml-3'>{division}</p>
                </div>
                <div className="flex items-center pl-2">
                    <IconLevel className="mr-1 hidden lg:block" />
                    <p className='ml-3'>{level}</p>
                </div>
                <div className="flex items-center pl-2">
                    <IconMapPin className="mr-1 hidden lg:block" />
                    <p className='ml-3'>{location}</p>
                </div>
            </div>
        </div>
    )
}

export const BoxProcedure = ({ noStep }) => {
    const [isMoreInfo, setIsMoreInfo] = useState(false);

    return (
        <button onClick={() => setIsMoreInfo(!isMoreInfo)} className={`flex relative ${isMoreInfo ? 'bg-gradient-to-br from-pink-600 to-pink-500 text-white' : 'bg-white'} text-left shadow mb-7 py-2 pl-6 pr-2 rounded-md transition-all duration-300 text-ellipsis`}>
            <span className={`px-3 py-1 absolute -top-4 -left-4 ${isMoreInfo ? 'bg-white text-black' : 'bg-pink-600 text-white'} shadow-md text-white rounded-full font-semibold`}>{noStep}</span>
            <p className={`active:text-white`}>{isMoreInfo ? 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta, eligendi. Illum, voluptate. Facere nulla fugiat aliquid, ratione recusandae aut dolor tempore animi rerum, nisi fugit tempora eligendi repellendus aspernatur asperiores?' : 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'}</p>
        </button>
    )
}

export const BoxProcedureDks = ({ noStep, label, desc }) => {
    const [isShowModal, setIsShowModal] = useState(false);

    return (
        <div className={`relative flex flex-col items-center h-48 bg-white z-20 shadow rounded-xl p-3 cursor-default flex-wrap`} onMouseEnter={() => setIsShowModal(true)} onMouseLeave={() => setIsShowModal(false)}>
            <h3 className={`absolute -top-5 ${isShowModal ? 'bg-white text-black' : 'bg-pink-500 text-white'} px-5 py-2 rounded-full font-semibold text-xl z-10 transition-colors duration-300`}>{noStep}</h3>
            <p className="h-full flex justify-center items-center font-semibold text-lg text-center">{label}</p>
            <p className={`transition-all overflow-x-hidden overflow-y-scroll scrollbar-hidden absolute bg-gradient-to-t pt-6 from-pink-700 to-pink-600 text-white h-full w-full rounded-xl p-3 ${isShowModal ? 'bottom-0 opacity-100 z-0' : '-bottom-2 opacity-0 -z-0'} duration-500`}>{desc}</p>
        </div>
    )
}