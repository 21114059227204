import { useState } from 'react';
import { HiMenu as IconMenu } from 'react-icons/hi';
import { IoMdClose as IconClose } from 'react-icons/io';
import { Link } from 'react-router-dom';

const Navbar = ({isOpenMenu, onOpenMenu, isNotChangeColor, isChangeColorMenuButton, forcedChangeColor}) => {
    const [isChangeColor, setIsChangeColor] = useState(false);

    function changeColor() {
        if(window.scrollY > 40) {
            setIsChangeColor(true);
        } else {
            setIsChangeColor(false);
        }
    }

    window.addEventListener('scroll', changeColor);

    return (
        // <nav className={`overflow-hidden sticky top-0 w-full py-3 px-7 md:px-28 ${isChangeColor ? "bg-white" : "bg-transparent"} shadow md:flex self-center items-center justify-center`}>
        <nav className={`${isNotChangeColor ? 'sticky' : 'fixed'} h-16 md:flex w-full top-0 left-0 z-[999] transition-all ease-in overflow-hidden md:pl-[15%] md:pr-[10%] px-7 py-3 ${isChangeColor || isNotChangeColor || forcedChangeColor ? "bg-white shadow" : "bg-transparent"}`}>
            <div className='inline-flex w-full lg:w-1/3 items-center justify-between'>
                <Link to={"/"} className='w-24 transition-all'>
                    {!isChangeColorMenuButton || isChangeColor ? <img src={require('../../assets/img/Logo_Career_Mutif.png')} alt={'Logo Mutif Career'} className={"w-full"} /> : <img src={require('../../assets/img/Logo_Mutif_Career_White.png')} alt={'Logo Mutif Career'} className={"w-full fill-white"} />}
                </Link>
                <span className={`${!isChangeColorMenuButton || isChangeColor ? 'text-slate-900' : 'text-white'} cursor-pointer rounded active:text-pink-600 md:hidden block`} onClick={onOpenMenu}>
                    { isOpenMenu ? <IconClose size={30} /> : <IconMenu size={30} />}
                </span>
            </div>

            {/* <div role={'menubar'} className={`bg-white md:flex md:items-center self-center transition-opacity justify-center z-10 md:z-auto md:static absolute w-full left-0 md:w-auto md:py-0 py-4 md:pl-0 pl-7 md:opacity-100 ${isOpenMenu ? `opacity-100 translate-x-0` : `opacity-0 -translate-y-3 md:-translate-y-0`} */}
            {/* <div role={'menubar'} className={`md:flex md:items-center self-center justify-center z-10 md:z-auto md:static hidden ${!isChangeColorMenuButton || isChangeColor ? 'text-slate-900' : 'text-white'}`}>
                <OptListNav title={'Home'} />
                <OptListNav title={'Job'} />
                <OptListNav title={'About'} />
                <OptListNav title={'LifeAtMutif'} />
            </div> */}
            <div className='lg:w-1/3'>

            </div>
        </nav>
    )
}

export default Navbar;